import produce, { Draft } from 'immer'
import {
  SET_LOCATION_DETAIL_DATA,
  FETCH_LOCATION_DETAIL_REQUEST,
  FETCH_LOCATION_POPULAR_AREAS_REQUEST,
  SET_LOCATION_POPULAR_AREAS_DATA,
  FETCH_LOCATION_RECOMMENDED_SPACES_REQUEST,
  SET_LOCATION_RECOMMENDED_SPACES_DATA,
  FETCH_LOCATION_COIN_LOCKERS_REQUEST,
  SET_LOCATION_COIN_LOCKERS_DATA,
  FETCH_LOCATION_STATIONS_REQUEST,
  SET_LOCATION_STATIONS_DATA,
  FETCH_LOCATION_FACILITIES_REQUEST,
  SET_LOCATION_FACILITIES_DATA,
} from './types'

import {
  setLocationDetailData,
  fetchLocationDetailRequest,
  fetchLocationPopularAreasRequest,
  setLocationPopularAreasData,
  fetchLocationRecommendedSpacesRequest,
  setLocationRecommendedSpacesData,
  fetchLocationCoinLockersRequest,
  setLocationCoinLockersData,
  fetchLocationStationsRequest,
  setLocationStationsData,
  fetchLocationFacilitiesRequest,
  setLocationFacilitiesData,
} from './actions'

import {
  LocationDetail,
  LocationPopularAreas,
  LocationRecommendedSpace,
  LocationCoinLocker,
  LocationStations,
  LocationFacilities,
} from './models'

const initialState = {
  data: { result: [], entities: {} },
}

export type LocationDetailState = Readonly<{
  data: {
    result: LocationDetail
  }
}>

export const locationDetailReducer = produce(
  (
    draft: Draft<LocationDetailState>,
    action: ReturnType<typeof setLocationDetailData> | ReturnType<typeof fetchLocationDetailRequest>
  ) => {
    switch (action.type) {
      case FETCH_LOCATION_DETAIL_REQUEST:
        draft
        break
      case SET_LOCATION_DETAIL_DATA:
        draft.data.result = action.payload.locationDetail.result
        break
      default:
        draft
    }
  },
  initialState
)

export type LocationPopularAreasState = Readonly<{
  data: {
    result: LocationPopularAreas
  }
}>

export const LocationPopularAreasReducer = produce(
  (
    draft: Draft<LocationPopularAreasState>,
    action:
      | ReturnType<typeof setLocationPopularAreasData>
      | ReturnType<typeof fetchLocationPopularAreasRequest>
  ) => {
    switch (action.type) {
      case FETCH_LOCATION_POPULAR_AREAS_REQUEST:
        draft
        break
      case SET_LOCATION_POPULAR_AREAS_DATA:
        draft.data.result = action.payload.locationPopularAreas.result
        break
      default:
        draft
    }
  },
  initialState
)

//------------------

//おすすめ預かり場所一覧
export type LocationRecommendedSpacesState = Readonly<{
  data: {
    result: {
      spaces: LocationRecommendedSpace[]
      total_count: number
    }
  }
}>

export const LocationRecommendedSpacesReducer = produce(
  (
    draft: Draft<LocationRecommendedSpacesState>,
    action:
      | ReturnType<typeof setLocationRecommendedSpacesData>
      | ReturnType<typeof fetchLocationRecommendedSpacesRequest>
  ) => {
    switch (action.type) {
      case FETCH_LOCATION_RECOMMENDED_SPACES_REQUEST:
        draft
        break
      case SET_LOCATION_RECOMMENDED_SPACES_DATA:
        draft.data.result = action.payload.locationRecommendedSpaces.result
        break
      default:
        draft
    }
  },
  initialState
)

//------------------

// コインロッカー
export type LocationCoinLockersState = Readonly<{
  data: {
    result: LocationCoinLocker[]
  }
}>

export const LocationCoinLockersReducer = produce(
  (
    draft: Draft<LocationCoinLockersState>,
    action:
      | ReturnType<typeof setLocationCoinLockersData>
      | ReturnType<typeof fetchLocationCoinLockersRequest>
  ) => {
    switch (action.type) {
      case FETCH_LOCATION_COIN_LOCKERS_REQUEST:
        draft
        break
      case SET_LOCATION_COIN_LOCKERS_DATA:
        draft.data.result = action.payload.locationCoinLockers.result
        break
      default:
        draft
    }
  },
  initialState
)

//------------------

// 駅
export type LocationStationsState = Readonly<{
  data: {
    result: LocationStations
  }
}>

export const LocationStationsReducer = produce(
  (
    draft: Draft<LocationStationsState>,
    action:
      | ReturnType<typeof setLocationStationsData>
      | ReturnType<typeof fetchLocationStationsRequest>
  ) => {
    switch (action.type) {
      case FETCH_LOCATION_STATIONS_REQUEST:
        draft
        break
      case SET_LOCATION_STATIONS_DATA:
        draft.data.result = action.payload.locationStations.result
        break
      default:
        draft
    }
  },
  initialState
)

//------------------

// 施設
export type LocationFacilitiesState = Readonly<{
  data: {
    result: LocationFacilities
  }
}>

export const LocationFacilitiesReducer = produce(
  (
    draft: Draft<LocationFacilitiesState>,
    action:
      | ReturnType<typeof setLocationFacilitiesData>
      | ReturnType<typeof fetchLocationFacilitiesRequest>
  ) => {
    switch (action.type) {
      case FETCH_LOCATION_FACILITIES_REQUEST:
        draft
        break
      case SET_LOCATION_FACILITIES_DATA:
        draft.data.result = action.payload.locationFacilities.result
        break
      default:
        draft
    }
  },
  initialState
)
