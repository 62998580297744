import axios, { AxiosRequestConfig } from 'axios'

const axiosConfig: AxiosRequestConfig = {
  timeout: 30000,
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'CLOAK-API-KEY': process.env.CLOAK_API_KEY,
    'Content-Type': 'application/json;charset=utf-8',
    xsrfCookieName: 'CSRF-TOKEN',
    xsrfHeaderName: 'X-CSRF-Token',
    Accept: 'application/json',
    'Accept-Language': 'ja-JP',
  },
  responseType: 'json',
}

export const client = axios.create({
  ...axiosConfig,
  baseURL: process.env.API_URL,
})

export const authClient = axios.create({
  ...axiosConfig,
  baseURL: process.env.AUTH_API_URL,
})

export const spaceClient = axios.create({
  ...axiosConfig,
  baseURL: process.env.SPACE_API_URL,
})

export const bookingClient = axios.create({
  ...axiosConfig,
  baseURL: process.env.BOOKING_API_URL,
})

export const search = axios.create({
  baseURL: process.env.ES_URL,
  timeout: 30000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json;charset=utf-8',
    Accept: 'application/json',
  },
  responseType: 'json',
})
