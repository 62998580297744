import {
  FETCH_BOOKINGS_REQUEST,
  FETCH_BOOKING_DETAIL_REQUEST,
  FETCH_BOOKING_REQUEST,
  FETCH_CANCEL_BOOKING_REQUEST,
  FETCH_BOOKING_INFO_REQUEST,
} from './types'
import { takeLatest } from 'redux-saga/effects'
import {
  fetchBookingDetailSaga,
  fetchBookingSaga,
  fetchCancelBookingSaga,
  fetchBookingsSaga,
  fetchBookingInfoSaga,
} from './sagas'

export function* watchBookingSaga() {
  yield takeLatest(FETCH_BOOKING_REQUEST, fetchBookingSaga)
}

export function* watchBookingInfoSaga() {
  yield takeLatest(FETCH_BOOKING_INFO_REQUEST, fetchBookingInfoSaga)
}

export function* watchBookingDetailSaga() {
  yield takeLatest(FETCH_BOOKING_DETAIL_REQUEST, fetchBookingDetailSaga)
}

export function* watchCancelBookingSaga() {
  yield takeLatest(FETCH_CANCEL_BOOKING_REQUEST, fetchCancelBookingSaga)
}

export function* watchBookingsSaga() {
  yield takeLatest(FETCH_BOOKINGS_REQUEST, fetchBookingsSaga)
}
