import * as apis from './apis'
import { call, put } from 'redux-saga/effects'
import {
  setLocationDetailData,
  setLocationPopularAreasData,
  setLocationRecommendedSpacesData,
  setLocationCoinLockersData,
  setLocationStationsData,
  setLocationFacilitiesData,
} from './actions'
import { loadingStopped, loadingStarted, catchErrors } from '../app/actions'
import router from 'next/router'

//Location 詳細
export function* fetchLocationDetailSaga(action) {
  try {
    yield put(loadingStarted())
    const params = action.payload.params
    const res = yield call(apis.getLocationDetail, params)
    yield put(setLocationDetailData(res.data))
  } catch (error) {
    switch (error.response.status) {
      case 400:
        router.push('/404')
        break
      case 403:
        yield put(
          catchErrors(
            error.response?.data?.message || 'このアカウントは規約違反により削除されました。'
          )
        )
        break
      default:
        yield put(catchErrors(error))
        break
    }
  } finally {
    yield put(loadingStopped())
  }
}

//人気預かりエリア一覧
export function* fetchLocationPopularAreasSaga(action) {
  try {
    yield put(loadingStarted())
    const params = action.payload.params

    const res = yield call(apis.getLocationPopularAreas, params)
    yield put(setLocationPopularAreasData(res.data))
  } catch (error) {
    switch (error.response.status) {
      // case 400:
      //   router.push('/404')
      //   break
      case 403:
        yield put(
          catchErrors(
            error.response?.data?.message || 'このアカウントは規約違反により削除されました。'
          )
        )
        break
      default:
        yield put(catchErrors(error))
        break
    }
  } finally {
    yield put(loadingStopped())
  }
}

//おすすめ預かり場所一覧
export function* fetchLocationRecommendedSpacesSaga(action) {
  try {
    yield put(loadingStarted())
    const params = action.payload.params

    const res = yield call(apis.getLocationRecommendedSpaces, params)
    yield put(setLocationRecommendedSpacesData(res.data))
  } catch (error) {
    switch (error.response.status) {
      // case 400:
      //   router.push('/404')
      //   break
      case 403:
        yield put(
          catchErrors(
            error.response?.data?.message || 'このアカウントは規約違反により削除されました。'
          )
        )
        break
      default:
        yield put(catchErrors(error))
        break
    }
  } finally {
    yield put(loadingStopped())
  }
}

//コインロッカー一覧
export function* fetchLocationCoinLockersSaga(action) {
  try {
    yield put(loadingStarted())
    const params = action.payload.params

    const res = yield call(apis.getLocationCoinLockers, params)
    yield put(setLocationCoinLockersData(res.data))
  } catch (error) {
    switch (error.response.status) {
      // case 400:
      //   router.push('/404')
      //   break
      case 403:
        yield put(
          catchErrors(
            error.response?.data?.message || 'このアカウントは規約違反により削除されました。'
          )
        )
        break
      default:
        yield put(catchErrors(error))
        break
    }
  } finally {
    yield put(loadingStopped())
  }
}

//駅一覧
export function* fetchLocationStationsSaga(action) {
  try {
    yield put(loadingStarted())

    const countryCode = action.payload.country_code
    const prefectureId = action.payload.prefecture_id
    const cityId = action.payload.city_id

    const res = yield call(apis.getLocationStations, countryCode, prefectureId, cityId)
    yield put(setLocationStationsData(res.data))
  } catch (error) {
    switch (error.response.status) {
      // case 400:
      //   router.push('/404')
      //   break
      case 403:
        yield put(
          catchErrors(
            error.response?.data?.message || 'このアカウントは規約違反により削除されました。'
          )
        )
        break
      default:
        yield put(catchErrors(error))
        break
    }
  } finally {
    yield put(loadingStopped())
  }
}

//施設一覧
export function* fetchLocationFacilitiesSaga(action) {
  try {
    yield put(loadingStarted())

    const countryCode = action.payload.country_code
    const prefectureId = action.payload.prefecture_id
    const cityId = action.payload.city_id

    const res = yield call(apis.getLocationFacilities, countryCode, prefectureId, cityId)
    yield put(setLocationFacilitiesData(res.data))
  } catch (error) {
    switch (error.response.status) {
      // case 400:
      //   router.push('/404')
      //   break
      case 403:
        yield put(
          catchErrors(
            error.response?.data?.message || 'このアカウントは規約違反により削除されました。'
          )
        )
        break
      default:
        yield put(catchErrors(error))
        break
    }
  } finally {
    yield put(loadingStopped())
  }
}
