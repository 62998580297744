import { spaceClient } from 'lib/axios'

const API_PREFECTURES = '/api/app/v1/prefectures'
const API_STATIONS = '/api/app/v1/stations'
const API_FACILITIES = '/api/app/v1/facilities'

export const getPrefectures = () => spaceClient.get(API_PREFECTURES)

export const getStations = (prefectureId) =>
  spaceClient.get(`${API_PREFECTURES}/${prefectureId}/stations`)

export const getFacilities = (prefectureId) =>
  spaceClient.get(`${API_PREFECTURES}/${prefectureId}/facilities`)

export const getPrefectureDetail = (slug, locale?) =>
  spaceClient.get(`${API_PREFECTURES}/${slug}`, { headers: { 'Accept-Language': locale || 'ja' } })

export const getStationDetail = (id, locale?) =>
  spaceClient.get(`${API_STATIONS}/${id}`, {
    headers: { 'Accept-Language': locale || 'ja' },
  })

export const getFacilityDetail = (id) => spaceClient.get(`${API_FACILITIES}/${id}`)
