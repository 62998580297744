import Router from 'next/router'

/**
 * LocalStorageに保存されている
 * 検索パラメーターに redirect_urlが存在していたら、そのURLにリダイレクトさせる
 */
export function useAuthRedirect() {
  const localStorageParams = JSON.parse(localStorage.getItem('search_params'))
  localStorageParams?.redirect_url
    ? Router.push(localStorageParams.redirect_url)
    : Router.push('/locations?test=1')
}
