import Router from 'next/router'
import nookies from 'nookies'
import * as apis from './apis'
import { call, put } from 'redux-saga/effects'
import { setUserData } from '../user/actions'
import { setFacebookUserData, setGoogleUserData } from './actions'
import { loadingStarted, loadingStopped, catchErrors, refreshErrors } from '../app/actions'
import { useAuthRedirect } from 'hooks/auth/redirect'
import { sendGtmEvent, GTM_EVENT_LOGIN_COMPLETE } from 'lib/gtag'

function jwtSetCookie(jwt) {
  nookies.set(null, 'jwt_token', jwt, {
    maxAge: 30 * 24 * 60 * 60,
    path: '/',
  })
}

export function* signInSaga(action) {
  try {
    const {
      payload: { auth },
    } = action
    yield put(loadingStarted())
    yield put(refreshErrors())
    let params

    if (auth.salt) {
      params = {
        email: auth.email,
        salt: auth.salt,
        code: auth.code,
      }
    } else {
      params = {
        phone_number: auth.phone_number,
        code: auth.code,
        session_info: auth.session_info,
      }
    }

    const res = yield call(apis.signIn, params)
    if (res.status === 200) {
      yield put(setUserData(res.data.user))
      // TODO: カントリーデータの保存
      // yield put(setCountryData())
      jwtSetCookie(res.data.jwt)

      // GTM送信
      sendGtmEvent({ eventName: GTM_EVENT_LOGIN_COMPLETE })

      // リダイレクト
      useAuthRedirect()
    } else {
      yield put(catchErrors('unexpected_error'))
    }
  } catch (error) {
    switch (error.response.data.errors[0].error_code) {
      case 'signin_email_not_found':
      case 'signin_invalid_email_password':
        yield put(catchErrors(`${error.response.data.errors[0].error_code}`))
        break
      case 'authentication_failed':
        yield put(catchErrors('authentication_failed'))
        break
      default:
        yield put(catchErrors('unexpected_error'))
        break
    }
  } finally {
    yield put(loadingStopped())
  }
}

export function* signUpSaga(action) {
  try {
    const {
      payload: { auth },
    } = action

    yield put(loadingStarted())
    yield put(refreshErrors())

    const params: any = {
      otp_type: auth.otp_type,
      email: auth.email,
      first_name: auth.first_name,
      last_name: auth.last_name,
      country_id: auth.country_id,
      phone_number: auth.phone_number,
      id_token: auth.id_token,
    }

    if ('uid_google' in auth) {
      params.uid_google = auth.uid_google
    }

    if ('uid_fb' in auth) {
      params.uid_fb = auth.uid_fb
    }

    const res = yield call(apis.signUp, params)
    if (res.status === 201) {
      yield put(setUserData(res.data.user))
      // TODO: カントリーデータの保存
      // yield put(setCountryData())
      jwtSetCookie(res.data.users.jwt)
      useAuthRedirect()
    } else {
      yield put(catchErrors('unexpected_error'))
    }
  } catch (error) {
    switch (error.response.status) {
      case 400:
        // TODO: error_codeをもとに、フロントでメッセージの出し分けを行う
        yield put(catchErrors('email_used'))
        break
      case 403:
        yield put(
          catchErrors(
            error.response?.data?.message || 'このアカウントは規約違反により削除されました。'
          )
        )
        break
      default:
        yield put(catchErrors('unexpected_error'))
        break
    }
  } finally {
    yield put(loadingStopped())
  }
}

export function* facebookSignUpSage(action) {
  try {
    yield put(loadingStarted())
    const accessToken = action.payload.accessToken
    const res = yield call(apis.facebookSignUp, accessToken)
    yield put(setFacebookUserData(res))
  } catch (error) {
    switch (error.response.data.errors[0].error_code) {
      case 'invalid_facebook_account':
      case 'facebook_id_used':
      case 'user_already_exist_google':
      case 'user_already_exist_apple':
      case 'facebook_email_used':
        yield put(catchErrors(`${error.response.data.errors[0].error_code}`))
        break
      default:
        yield put(catchErrors('unexpected_error'))
        break
    }
  } finally {
    yield put(loadingStopped())
  }
}

export function* facebookSignInSage(action) {
  try {
    yield put(loadingStarted())
    const accessToken = action.payload.accessToken
    const res = yield call(apis.facebookSignIn, accessToken)
    yield put(setFacebookUserData(res))
    jwtSetCookie(res.data.jwt)
    useAuthRedirect()
  } catch (error) {
    switch (error.response.data.errors[0].error_code) {
      case 'user_not_found_for_facebook':
      case 'user_already_exist_google':
      case 'user_already_exist_apple':
      case 'facebook_email_used':
        yield put(catchErrors(`${error.response.data.errors[0].error_code}`))
        break
      default:
        yield put(catchErrors('unexpected_error'))
        break
    }
  } finally {
    yield put(loadingStopped())
  }
}

export function* googleSignUpSage(action) {
  try {
    yield put(loadingStarted())
    const res = yield call(apis.googleSignUp, action.payload)
    yield put(setGoogleUserData(res))
  } catch (error) {
    switch (error.response.data.errors[0].error_code) {
      case 'user_not_found_for_google':
      case 'google_id_used':
      case 'user_already_exist_facebook':
      case 'user_already_exist_apple':
      case 'google_email_used':
        yield put(catchErrors(`${error.response.data.errors[0].error_code}`))
        break
      default:
        yield put(catchErrors('unexpected_error'))
        break
    }
  } finally {
    yield put(loadingStopped())
  }
}

export function* googleSignInSage(action) {
  try {
    yield put(loadingStarted())
    const accessToken = action.payload.accessToken
    const res = yield call(apis.googleSignIn, accessToken)
    jwtSetCookie(res.data.jwt)
    yield put(setGoogleUserData(res))
    yield put(setUserData(res.data.user))
    useAuthRedirect()
  } catch (error) {
    switch (error.response.data.errors[0].error_code) {
      case 'user_already_exist_facebook':
      case 'user_already_exist_apple':
      case 'google_email_used':
        yield put(catchErrors(`${error.response.data.errors[0].error_code}`))
        break
      default:
        yield put(catchErrors('unexpected_error'))
        break
    }
  } finally {
    yield put(loadingStopped())
  }
}
