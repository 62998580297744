export const AUTH_SIGNIN_REQUEST = 'AUTH_SIGNIN_REQUEST'
export const AUTH_SIGNIN_SUCCESS = 'AUTH_SIGNIN_SUCCESS'
export const AUTH_SIGNIN_FAILURE = 'AUTH_SIGNIN_FAILURE'
export const AUTH_SIGNUP_REQUEST = 'AUTH_SIGNUP_REQUEST'
export const AUTH_SIGNUP_SUCCESS = 'AUTH_SIGNUP_SUCCESS'
export const AUTH_SIGNUP_FAILURE = 'AUTH_SIGNUP_FAILURE'
export const FACEBOOK_SIGNUP_REQUEST = 'FACEBOOK_SIGNUP_REQUEST'
export const SET_FACEBOOK_USER_DATA = 'SET_FACEBOOK_USER_DATA'
export const FACEBOOK_SIGNIN_REQUEST = 'FACEBOOK_SIGNIN_REQUEST'
export const GOOGLE_SIGNUP_REQUEST = 'GOOGLE_SIGNUP_REQUEST'
export const GOOGLE_SIGNIN_REQUEST = 'GOOGLE_SIGNIN_REQUEST'
export const SET_GOOGLE_USER_DATA = 'SET_GOOGLE_USER_DATA'
