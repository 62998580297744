import * as watcherSagas from './watcherSagas'

export {
  bookingSuccessReducer,
  bookingInfoReducer,
  bookingDetailReducer,
  successCancelBookingReducer,
  bookingsReducer,
} from './reducers'
export const bookingWatcherSagas = Object.values(watcherSagas)
export type {
  BookingState,
  BookingInfoState,
  BookingDetailState,
  CancelBookingState,
  BookingsState,
} from './reducers'
