import { schema, normalize, denormalize } from 'normalizr'
import { ReviewUser } from '../user/models'

export type Coordinate = {
  lat: number | string
  lon: number | string
}

export type Filter = Coordinate & {
  user_id?: string
  booked_from?: string
  booked_to?: string
  bag_size?: number
  suitcase_size?: number
  has_wifi?: boolean | false
  has_charge?: boolean | false
  available_in_english?: boolean | false
  no_smoking?: boolean | false
  has_elevator?: boolean | false
  time_zone?: string
  os_name?: string
  browser_name?: string
  locale?: string
  available_24_hour: boolean | false
}

export enum SpaceCurrency {
  cny = '¥',
  twd = 'NT$',
  jpy = '¥',
  krw = '₩',
}
export type Currency = 'cny' | 'twd' | 'jpy' | 'krw'

export type SpaceSource = {
  available_from: string
  available_to: string
  average_rating: number
  en_name: string
  large_locker_num: number
  location: string
  main_image_url: string
  name: string
  small_locker_num: number
  space_type: number
  status: number
  sub_category_id: number
  station_1_name: string
  station_1_en_name: string
  distance_from_station_1: number
  has_wifi: boolean
  has_charge: boolean
  available_in_english: boolean
  no_smoking: boolean
  has_elevator: boolean
  reviews: {
    comment?: string | null
    user_name?: string | null
    count?: number
  }
  bag_size: number
  suitcase_size: number
  currency?: Currency
}

export type Spaces = {
  fields: Array<Coordinate>
  sort: Array<number>
  _id: string
  _index: string
  _score: string
  _source: SpaceSource
  _type: string
}

export type Reviews = {
  comment: string
  created_at: string
  id: number
  rating: number
  review_user: ReviewUser
}

export type SpaceDetail = {
  space_id: string
  phone_number: string
  show_phone_number: boolean
  zip_code: string
  country_code: string
  prefecture_id: number
  city: string
  detailed_place: string
  building: string
  homepage_url: string
  fb_account: string
  tw_account: string
  insta_account: string
  latitude: number
  longitude: number
  name: string
  station_exit_1: string
  distance_from_station_1: number
  station_exit_2: string
  distance_from_station_2: number
  has_wifi: boolean
  has_charge: boolean
  available_in_english: boolean
  no_smoking: boolean
  has_elevator: boolean
  accept_on_site_payment: boolean
  available_24_hour: boolean
  small_locker_num: number
  intro_staff_name: string
  introduction: string
  extra_info: string
  prefecture: string
  prefecture_slug: string
  area_section: string
  sub_area_section: string
  station_name_1: string
  station_name_2: string
  open_at_today: string
  close_at_today: string
  open_at_mon: string
  close_at_mon: string
  open_at_tue: string
  close_at_tue: string
  open_at_wed: string
  close_at_wed: string
  open_at_thu: string
  close_at_thu: string
  open_at_fri: string
  close_at_fri: string
  open_at_sat: string
  close_at_sat: string
  open_at_sun: string
  close_at_sun: string
  large_locker_num: number
  intro_staff_image_url: string
  space_photos: Array<{ url: string; photo_description: string }>
  reviews: Array<Reviews>
  can_book_multiple_days: boolean
  localed_address: string
  average_review_rating: number
  reviews_count: number
  sub_category: string
  space_type: number
  jr_east_space_detail: {
    map_image: string
    locker_price: number
  }
  jr_west_space_detail: {
    locker_price: number
  }
  google_place_url: string
  currency?: Currency
  fully_status_updated_at?: string
}

//おすすめ
export type RecommendedSpace = {}

export type NormalizedSpace = Spaces

export const spaceSample = Object.freeze([
  {
    _index: 'cloak',
    _type: 'space',
    _id: 'eHBohkKs',
    _score: null,
    _source: {
      small_locker_num: 200,
      main_image_url:
        'https://local-cloak.s3-ap-northeast-1.amazonaws.com/space_photo_images/VCBC9Yru/2460f6fc65628af501cabbd52c055770d903b883.png?1569563506',
      large_locker_num: 200,
      sub_category_id: 40,
      space_type: 0,
      name: 'ecbo tapioka',
      en_name: 'ecbo tapioka',
      average_rating: 0,
      location: '35.659564,139.700025',
      status: 3,
    },
    fields: {
      location: [
        {
          lat: 35.659563965164125,
          lon: 139.70002496615052,
        },
      ],
    },
    sort: [2.12948033849295],
  },
  {
    _index: 'cloak',
    _type: 'space',
    _id: '0XEEXyJS',
    _score: null,
    _source: {
      small_locker_num: 100,
      main_image_url:
        'https://local-cloak.s3-ap-northeast-1.amazonaws.com/space_photo_images/hG3TmzQp/d633a33a80d5a4fc074497530fc1b7cc0e5fa901.jpeg?1574658546',
      large_locker_num: 100,
      sub_category_id: 40,
      space_type: 0,
      name: 'ecbocafe',
      en_name: 'ecbocafe',
      average_rating: 0,
      location: '35.658832,139.701184',
      status: 3,
    },
    fields: {
      location: [
        {
          lat: 35.658831973560154,
          lon: 139.70118393190205,
        },
      ],
    },
    sort: [2.18675181983451],
  },
])

export const spaceNormalizrSchemaKey = 'spaces'

export const spaceNormalizrSchema = new schema.Entity(
  spaceNormalizrSchemaKey,
  {},
  { idAttribute: '_id' }
)

export type NormalizedSpaces = {
  [_id: string]: NormalizedSpace
}

export const normalizeSpaces = (spaces) =>
  normalize<
    Spaces,
    {
      [spaceNormalizrSchemaKey]: NormalizedSpaces
    },
    Spaces['_id'][]
  >(spaces, [spaceNormalizrSchema])

export const denormalizeSpaces = (spaces) =>
  denormalize(spaces.result, [spaceNormalizrSchema], spaces.entities)
