import * as apis from './apis'
import { call, put } from 'redux-saga/effects'
import { setCountriesData, setCitiesData } from './actions'
import { loadingStopped, loadingStarted, catchErrors } from '../app/actions'

// 国情報一覧を取得
export function* fetchCountriesSaga() {
  try {
    yield put(loadingStarted())

    const res = yield call(apis.getCountries)
    yield put(setCountriesData(res.data))
  } catch (error) {
    switch (error.response.status) {
      // case 400:
      //   router.push('/404')
      //   break
      case 403:
        yield put(
          catchErrors(
            error.response?.data?.message || 'このアカウントは規約違反により削除されました。'
          )
        )
        break
      default:
        yield put(catchErrors(error))
        break
    }
  } finally {
    yield put(loadingStopped())
  }
}

// 州情報一覧を取得
export function* fetchCitiesSaga(action) {
  try {
    yield put(loadingStarted())

    const countryCode: string = action.payload.countryCode
    const res = yield call(apis.getCities, countryCode)

    yield put(setCitiesData(res.data))
  } catch (error) {
    switch (error.response.status) {
      // case 400:
      //   router.push('/404')
      //   break
      case 403:
        yield put(
          catchErrors(
            error.response?.data?.message || 'このアカウントは規約違反により削除されました。'
          )
        )
        break
      default:
        yield put(catchErrors(error))
        break
    }
  } finally {
    yield put(loadingStopped())
  }
}
