import { schema, normalize, denormalize } from 'normalizr'

export type LockerPlan = {
  id: string
  planType: number
  price: number
  createdAt: string
  updatedAt: string
}
export type NormalizedLockerPlan = LockerPlan

export const lockerPlanSamples = Object.freeze([
  Object.freeze({
    id: '1',
    planType: 0,
    price: 300,
    createdAt: '2000-01-01 00:00',
    updatedAt: '2000-01-01 00:00',
  }),
  Object.freeze({
    id: '2',
    planType: 1,
    price: 600,
    createdAt: '2000-01-01 00:00',
    updatedAt: '2000-01-01 00:00',
  }),
] as LockerPlan[])

export const lockerPlanNormalizrSchemaKey = 'lockerPlans'

export const lockerPlanNormalizrSchema = new schema.Entity<LockerPlan>(
  lockerPlanNormalizrSchemaKey,
  {},
  {
    idAttribute: 'id',
  }
)

export type NormalizedLockerPlans = {
  [id: string]: NormalizedLockerPlan
}

export const normalizeLockerPlans = (lockerPlans: LockerPlan[]) =>
  normalize<
    LockerPlan,
    { [lockerPlanNormalizrSchemaKey]: NormalizedLockerPlans },
    LockerPlan['id'][]
  >(lockerPlans, [lockerPlanNormalizrSchema])

export const denormalizeLockerPlans = (
  lockerPlans: ReturnType<typeof normalizeLockerPlans>
): LockerPlan[] =>
  denormalize(lockerPlans.result, [lockerPlanNormalizrSchema], lockerPlans.entities)
