/**
 * 言語の種類でAccept Language を返す
 * @param locale string 言語
 * @returns string Accept-Language
 */
export default function getAcceptLanguageByLocale(locale: string) {
  switch (locale) {
    case 'en':
      return 'en-US'
    case 'zh_cn':
    case 'zh_CN':
    case 'zh-cn':
    case 'zh-CN':
      return 'zh-CN'
    case 'zh_tw':
    case 'zh_TW':
    case 'zh-tw':
    case 'zh-TW':
      return 'zh-TW'
    default:
      return 'ja-JP'
  }
}
