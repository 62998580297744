import {
  FETCH_PREFECTURES_REQUEST,
  FETCH_STATIONS_REQUEST,
  FETCH_FACILITIES_REQUEST,
  FETCH_LOCATION_DETAIL_REQUEST,
} from './types'
import { takeLatest } from 'redux-saga/effects'
import {
  fetchPrefecturesSaga,
  fetchStationsSaga,
  fetchFacilitiesSaga,
  fetchLocationDetailSaga,
} from './sagas'

export function* watchPrefecturesSaga() {
  yield takeLatest(FETCH_PREFECTURES_REQUEST, fetchPrefecturesSaga)
}

export function* watchStationsSaga() {
  yield takeLatest(FETCH_STATIONS_REQUEST, fetchStationsSaga)
}

export function* watchFacilitiesSaga() {
  yield takeLatest(FETCH_FACILITIES_REQUEST, fetchFacilitiesSaga)
}

export function* watchLocationDetailSaga() {
  yield takeLatest(FETCH_LOCATION_DETAIL_REQUEST, fetchLocationDetailSaga)
}
