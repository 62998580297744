import {
  FETCH_LOCATION_DETAIL_REQUEST,
  FETCH_LOCATION_POPULAR_AREAS_REQUEST,
  FETCH_LOCATION_RECOMMENDED_SPACES_REQUEST,
  FETCH_LOCATION_COIN_LOCKERS_REQUEST,
  FETCH_LOCATION_STATIONS_REQUEST,
  FETCH_LOCATION_FACILITIES_REQUEST,
} from './types'
import { takeLatest } from 'redux-saga/effects'
import {
  fetchLocationDetailSaga,
  fetchLocationPopularAreasSaga,
  fetchLocationRecommendedSpacesSaga,
  fetchLocationCoinLockersSaga,
  fetchLocationStationsSaga,
  fetchLocationFacilitiesSaga,
} from './sagas'

export function* watchLocationDetailSaga() {
  yield takeLatest(FETCH_LOCATION_DETAIL_REQUEST, fetchLocationDetailSaga)
}

export function* watchLocationPopularAreasSaga() {
  yield takeLatest(FETCH_LOCATION_POPULAR_AREAS_REQUEST, fetchLocationPopularAreasSaga)
}

export function* watchLocationRecommendedSpacesSaga() {
  yield takeLatest(FETCH_LOCATION_RECOMMENDED_SPACES_REQUEST, fetchLocationRecommendedSpacesSaga)
}

export function* watchLocationCoinLockersSaga() {
  yield takeLatest(FETCH_LOCATION_COIN_LOCKERS_REQUEST, fetchLocationCoinLockersSaga)
}

// 駅
export function* watchLocationStationsSaga() {
  yield takeLatest(FETCH_LOCATION_STATIONS_REQUEST, fetchLocationStationsSaga)
}

// 施設
export function* watchLocationFacilitiesSaga() {
  yield takeLatest(FETCH_LOCATION_FACILITIES_REQUEST, fetchLocationFacilitiesSaga)
}
