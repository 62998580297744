import produce, { Draft } from 'immer'
import {
  FETCH_BOOKING_REQUEST,
  SET_BOOKING_ID,
  FETCH_BOOKING_INFO_REQUEST,
  SET_BOOKING_INFO_DATA,
  FETCH_BOOKING_DETAIL_REQUEST,
  SET_BOOKING_DETAIL_DATA,
  FETCH_CANCEL_BOOKING_REQUEST,
  SET_SUCCESS_CANCEL_BOOKING,
  FETCH_BOOKINGS_REQUEST,
  SET_BOOKINGS_DATA,
} from './types'
import {
  fetchBookingDataRequest,
  fetchBookingInfoRequest,
  fetchBookingDetailRequest,
  fetchCancelBookingRequest,
  setBookingSuccess,
  setBookingInfo,
  setBookingDetail,
  setSuccessCancelBooking,
  setBookings,
  fetchBookingsRequest,
} from './actions'
import { Booking, BookingInfo, BookingDetail, Bookings } from './models'

export type BookingState = Readonly<{ data: { result: Booking } }>
export type BookingSuccessState = Readonly<{
  data: {
    result: {
      status: number
      id: string
    }
  }
}>
export type BookingInfoState = Readonly<{ data: { result: BookingInfo } }>
export type BookingDetailState = Readonly<{ data: { result: BookingDetail } }>
export type CancelBookingState = Readonly<{ data: { result: number } }>
export type BookingsState = Readonly<{ data: { result: Bookings } }>

const bookingSuccessState = {
  data: {
    result: {
      status: 0,
      id: '',
    },
  },
}

const bookingInfoState = {
  data: { result: {} },
}

const bookingDetailState = {
  data: { result: {} },
}

const cancelBookingState = {
  data: { result: 0 },
}

const bookingsState = {
  data: { result: [] },
}

export const bookingSuccessReducer = produce(
  (
    draft: Draft<BookingSuccessState>,
    action: ReturnType<typeof fetchBookingDataRequest> | ReturnType<typeof setBookingSuccess>
  ) => {
    switch (action.type) {
      case FETCH_BOOKING_REQUEST:
        draft
        break
      case SET_BOOKING_ID:
        draft.data.result = { status: action.payload.status, id: action.payload.bookingId }
        break
      default:
        draft
    }
  },
  bookingSuccessState
)

export const bookingInfoReducer = produce(
  (
    draft: Draft<BookingInfoState>,
    action: ReturnType<typeof fetchBookingInfoRequest> | ReturnType<typeof setBookingInfo>
  ) => {
    switch (action.type) {
      case FETCH_BOOKING_INFO_REQUEST:
        draft
        break
      case SET_BOOKING_INFO_DATA:
        draft.data.result = action.payload.bookingInfo.result
        break
      default:
        draft
    }
  },
  bookingInfoState
)

export const bookingDetailReducer = produce(
  (
    draft: Draft<BookingDetailState>,
    action: ReturnType<typeof fetchBookingDetailRequest> | ReturnType<typeof setBookingDetail>
  ) => {
    switch (action.type) {
      case FETCH_BOOKING_DETAIL_REQUEST:
        draft
        break
      case SET_BOOKING_DETAIL_DATA:
        draft.data.result = action.payload.bookingDetail.result
        break
      default:
        draft
    }
  },
  bookingDetailState
)

export const successCancelBookingReducer = produce(
  (
    draft: Draft<CancelBookingState>,
    action:
      | ReturnType<typeof fetchCancelBookingRequest>
      | ReturnType<typeof setSuccessCancelBooking>
  ) => {
    switch (action.type) {
      case FETCH_CANCEL_BOOKING_REQUEST:
        draft
        break
      case SET_SUCCESS_CANCEL_BOOKING:
        draft.data.result = action.payload.status.result
        break
      default:
        draft
    }
  },
  cancelBookingState
)

export const bookingsReducer = produce(
  (
    draft: Draft<BookingsState>,
    action: ReturnType<typeof fetchBookingsRequest> | ReturnType<typeof setBookings>
  ) => {
    switch (action.type) {
      case FETCH_BOOKINGS_REQUEST:
        draft
        break
      case SET_BOOKINGS_DATA:
        draft.data.result = action.payload.bookings.result
        break
      default:
        draft
    }
  },
  bookingsState
)
