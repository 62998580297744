import { FETCH_SPACES_REQUEST, FETCH_SPACE_DETAIL_REQUEST } from './types'
import { takeLatest } from 'redux-saga/effects'
import { fetchSpacesSaga, fetchSpaceDetailSaga } from './sagas'

export function* watchSpacesSaga() {
  yield takeLatest(FETCH_SPACES_REQUEST, fetchSpacesSaga)
}

export function* watchSpaceDetailSaga() {
  yield takeLatest(FETCH_SPACE_DETAIL_REQUEST, fetchSpaceDetailSaga)
}
