export const FETCH_BOOKING_REQUEST = 'FETCH_BOOKING_REQUEST'
export const SET_BOOKING_ID = 'SET_BOOKING_ID'
export const FETCH_BOOKING_INFO_REQUEST = 'FETCH_BOOKING_INFO_REQUEST'
export const SET_BOOKING_INFO_DATA = 'SET_BOOKING_INFO_DATA'
export const FETCH_BOOKING_DETAIL_REQUEST = 'FETCH_BOOKING_DETAIL_REQUEST'
export const SET_BOOKING_DETAIL_DATA = 'SET_BOOKING_DETAIL_DATA'
export const FETCH_CANCEL_BOOKING_REQUEST = 'FETCH_CANCEL_BOOKING_REQUEST'
export const SET_SUCCESS_CANCEL_BOOKING = 'SET_SUCCESS_CANCEL_BOOKING'
export const FETCH_BOOKINGS_REQUEST = 'FETCH_BOOKINGS_REQUEST'
export const SET_BOOKINGS_DATA = 'SET_BOOKINGS_DATA'
