import * as apis from './apis'
import { call, put } from 'redux-saga/effects'
import {
  setBookingDetail,
  setBookingSuccess,
  setBookingInfo,
  setBookings,
  setSuccessCancelBooking,
} from './actions'
import { loadingStopped, loadingStarted, catchErrors } from '../app/actions'

export function* fetchBookingSaga(action) {
  try {
    yield put(loadingStarted())
    const accessToken = action.payload.accessToken
    const params = action.payload.params
    const res = yield call(apis.postBooking, accessToken, params)
    yield put(setBookingSuccess(res.status, res.data.booking.booking_id))
  } catch (error) {
    switch (error.response.status) {
      case 400:
        yield put(catchErrors(`${error.response.data.errors[0].message}`))
        break
      case 403:
        yield put(
          catchErrors(
            error.response?.data?.message || 'このアカウントは規約違反により削除されました。'
          )
        )
        break
      default:
        yield put(catchErrors('unexpected_error'))
        break
    }
  } finally {
    yield put(loadingStopped())
  }
}

export function* fetchBookingInfoSaga(action) {
  try {
    yield put(loadingStarted())
    const accessToken = action.payload.accessToken
    const params = action.payload.params
    const res = yield call(apis.getBookingInfo, accessToken, params)
    yield put(setBookingInfo(res.data))
  } catch (error) {
    switch (error.response.status) {
      case 401:
        yield put(catchErrors(`${error.response.data.errors[0].message}`))
        break
      case 403:
        yield put(
          catchErrors(
            error.response?.data?.message || 'このアカウントは規約違反により削除されました。'
          )
        )
        break
      default:
        yield put(catchErrors('unexpected_error'))
        break
    }
  } finally {
    yield put(loadingStopped())
  }
}

export function* fetchBookingDetailSaga(action) {
  try {
    yield put(loadingStarted())
    const accessToken = action.payload.accessToken
    const bookingId = action.payload.bookingId
    const res = yield call(apis.getBookingDetail, accessToken, bookingId)
    yield put(setBookingDetail(res.data.booking))
  } catch (error) {
    switch (error.response.status) {
      case 400:
        yield put(catchErrors(`${error.response.data.errors[0].message}`))
        break
      case 403:
        yield put(
          catchErrors(
            error.response?.data?.message || 'このアカウントは規約違反により削除されました。'
          )
        )
        break
      default:
        yield put(catchErrors('unexpected_error'))
        break
    }
  } finally {
    yield put(loadingStopped())
  }
}

export function* fetchCancelBookingSaga(action) {
  try {
    yield put(loadingStarted())
    const accessToken = action.payload.accessToken
    const bookingId = action.payload.bookingId
    const res = yield call(apis.cancelBooking, accessToken, bookingId)
    yield put(setSuccessCancelBooking(res.status))
  } catch (error) {
    switch (error.response.status) {
      case 400:
        yield put(catchErrors(`${error.response.data.errors[0].message}`))
        break
      case 403:
        yield put(
          catchErrors(
            error.response?.data?.message || 'このアカウントは規約違反により削除されました。'
          )
        )
        break
      default:
        yield put(catchErrors('unexpected_error'))
        break
    }
  } finally {
    yield put(loadingStopped())
  }
}

export function* fetchBookingsSaga(action) {
  try {
    yield put(loadingStarted())
    const accessToken = action.payload.accessToken
    const res = yield call(apis.getBookings, accessToken)
    yield put(setBookings(res.data))
  } catch (error) {
    switch (error.response.status) {
      case 400:
        yield put(catchErrors(`${error.response.data.errors[0].message}`))
        break
      case 403:
        yield put(
          catchErrors(
            error.response?.data?.message || 'このアカウントは規約違反により削除されました。'
          )
        )
        break
      default:
        yield put(catchErrors('unexpected_error'))
        break
    }
  } finally {
    yield put(loadingStopped())
  }
}
