import React, { FC, useState, useEffect, createContext, PropsWithChildren, ReactNode } from 'react'
import { parseCookies } from 'nookies'
import { useDispatch } from 'react-redux'

import { fetchSetUserIdRequest } from 'state/ducks/user/actions'

type AuthContextProps = {
  isSigned: boolean
  userId: string
}

const AuthContext = createContext<AuthContextProps>({ isSigned: false, userId: '' })

type Props = {
  children: ReactNode
}

const AuthProvider: FC<Props> = ({ children }) => {
  const dispatch = useDispatch()

  const cookies = parseCookies()

  const [isSigned, setIsSigned] = useState<boolean>(false)
  const [userId] = useState<string>(cookies.user_id)

  useEffect(() => {
    if (cookies.jwt_token) return setIsSigned(true)
    return setIsSigned(false)
  }, [cookies.jwt_token])

  useEffect(() => {
    if (cookies.jwt_token) {
      dispatch(fetchSetUserIdRequest(cookies.jwt_token))
    }
  }, [cookies.jwt_token])

  return <AuthContext.Provider value={{ isSigned, userId }}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
