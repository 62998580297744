import { FETCH_LOCKER_PLAN_REQUEST, FETCH_LOCKER_PLAN_SUCCESS } from './types'
import { NormalizedLockerPlan, NormalizedLockerPlans } from './models'

export const fetchLockerPlanRequested = (): Readonly<{
  type: 'FETCH_LOCKER_PLAN_REQUEST'
}> => {
  return {
    type: FETCH_LOCKER_PLAN_REQUEST,
  }
}

export const fetchLockerPlanSuccess = (
  ids: NormalizedLockerPlan['id'][],
  lockerPlans: NormalizedLockerPlans
): Readonly<{
  type: 'FETCH_LOCKER_PLAN_SUCCESS'
  payload: {
    lockerPlan: {
      ids: NormalizedLockerPlan['id'][]
      entities: NormalizedLockerPlans
    }
  }
}> => {
  return {
    type: FETCH_LOCKER_PLAN_SUCCESS,
    payload: {
      lockerPlan: { ids, entities: lockerPlans },
    },
  }
}
