import Router from 'next/router'
import * as apis from './apis'
import { call, put } from 'redux-saga/effects'
import nookies from 'nookies'

import { setCreditCardData, setUserData } from './actions'
import { loadingStopped, loadingStarted, catchErrors, refreshErrors } from '../app/actions'

export function* fetchSetUserIdSaga(action) {
  try {
    yield put(loadingStarted())
    const accessToken = action.payload.accessToken
    const res = yield call(apis.getUser, accessToken)
    nookies.set(null, 'user_id', res.data.user.user_id, {
      maxAge: 30 * 24 * 60 * 60,
      path: '/',
    })
  } catch (error) {
    switch (error.response.status) {
      case 401:
        yield put(catchErrors('セッションの有効期限切れです'))
        Router.push('/users/sign_in')
        break
      case 403:
        yield put(
          catchErrors(
            error.response?.data?.message || 'このアカウントは規約違反により削除されました。'
          )
        )
        break
      default:
        yield put(catchErrors('予期せぬエラーが発生しました'))
        break
    }
  } finally {
    yield put(loadingStopped())
  }
}

export function* fetchUserSaga(action) {
  try {
    yield put(loadingStarted())
    const accessToken = action.payload.accessToken
    const res = yield call(apis.getUser, accessToken)
    yield put(setUserData(res.data.user))
  } catch (error) {
    switch (error.response.status) {
      case 401:
        yield put(catchErrors('セッションの有効期限切れです'))
        Router.push('/users/sign_in')
        break
      case 403:
        yield put(
          catchErrors(
            error.response?.data?.message || 'このアカウントは規約違反により削除されました。'
          )
        )
        break
      default:
        yield put(catchErrors('予期せぬエラーが発生しました'))
        break
    }
  } finally {
    yield put(loadingStopped())
  }
}

export function* fetchUpdateUserSaga(action) {
  try {
    yield put(loadingStarted())
    const res = yield call(apis.updateUser, action.payload)
    yield put(setUserData(res.data.user))
  } catch (error) {
    switch (error.response.status) {
      case 401:
        yield put(catchErrors('セッションの有効期限切れです'))
        Router.push('/users/sign_in')
        break
      case 403:
        yield put(
          catchErrors(
            error.response?.data?.message || 'このアカウントは規約違反により削除されました。'
          )
        )
        break
      default:
        yield put(catchErrors('予期せぬエラーが発生しました'))
        break
    }
  } finally {
    yield put(loadingStopped())
  }
}

export function* fetchDeleteUserSaga(action) {
  try {
    yield call(apis.deleteUser, action.payload)

    Router.push(
      {
        pathname: '/users/delete',
        query: { deleteSuccess: true },
      },
      '/users/delete'
    )
  } catch (error) {
    switch (error.response.status) {
      case 401:
        yield put(catchErrors('セッションの有効期限切れです'))
        Router.push('/users/sign_in')
        break
      case 403:
        yield put(
          catchErrors(
            error.response?.data?.message || 'このアカウントは規約違反により削除されました。'
          )
        )
        break
      default:
        yield put(catchErrors('予期せぬエラーが発生しました'))
        break
    }
  } finally {
    yield put(loadingStopped())
  }
}

export function* fetchCreditCardSaga(action) {
  try {
    yield put(loadingStarted())
    const accessToken = action.payload.accessToken
    const res = yield call(apis.getCreditCard, accessToken)
    yield put(setCreditCardData(res.data))
  } catch (error) {
    switch (error.response.status) {
      case 401:
        yield put(catchErrors('セッションの有効期限切れです'))
        break
      case 403:
        yield put(
          catchErrors(
            error.response?.data?.message || 'このアカウントは規約違反により削除されました。'
          )
        )
        break
      default:
        yield put(catchErrors('予期せぬエラーが発生しました'))
        break
    }
  } finally {
    yield put(loadingStopped())
  }
}

export function* fetchUpdateCreditCardSaga(action) {
  try {
    yield put(loadingStarted())
    yield put(refreshErrors())
    yield call(apis.updateCreditCard, action.payload.stripeToken, action.payload.accessToken)
    const res = yield call(apis.getCreditCard, action.payload.accessToken)
    yield put(setCreditCardData(res.data))
  } catch (error) {
    switch (error.response.status) {
      case 401:
        yield put(catchErrors('セッションの有効期限切れです'))
        break
      case 403:
        yield put(
          catchErrors(
            error.response?.data?.message || 'このアカウントは規約違反により削除されました。'
          )
        )
        break
      default:
        yield put(catchErrors('予期せぬエラーが発生しました'))
        break
    }
  } finally {
    yield put(loadingStopped())
  }
}

export function* fetchDeleteCreditCardSaga(action) {
  try {
    yield put(loadingStarted())
    yield put(refreshErrors())
    const res = yield call(apis.deleteCreateCard, action.payload.accessToken)
    yield put(setCreditCardData(res.data))
  } catch (error) {
    switch (error.response.status) {
      case 401:
        yield put(catchErrors('セッションの有効期限切れです'))
        break
      case 403:
        yield put(
          catchErrors(
            error.response?.data?.message || 'このアカウントは規約違反により削除されました。'
          )
        )
        break
      default:
        yield put(catchErrors('予期せぬエラーが発生しました'))
        break
    }
  } finally {
    yield put(loadingStopped())
  }
}
