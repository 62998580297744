/**
 * 言語の種類でLOCALE を返す
 * @param locale string 言語
 * @returns string LOCALE
 */
export default function getLocaleHeaderByLocale(locale: string) {
  switch (locale) {
    case 'en':
    case 'en-US':
    case 'en-us':
      return 'en'
    case 'zh_cn':
    case 'zh_CN':
    case 'zh-cn':
    case 'zh-CN':
      return 'zh-CN'
    case 'zh_tw':
    case 'zh_TW':
    case 'zh-tw':
    case 'zh-TW':
      return 'zh-TW'
    default:
      return 'ja'
  }
}
