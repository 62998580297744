import * as apis from './apis'
import { call, put } from 'redux-saga/effects'
import { setSpaceDetailData, setSpacesData } from './actions'
import { loadingStopped, loadingStarted, catchErrors } from '../app/actions'
import { normalizeSpaces, spaceNormalizrSchemaKey } from './models'
import { i18n } from 'next-i18next'

export function* fetchSpacesSaga(action) {
  try {
    yield put(loadingStarted())
    const params = action.payload.params
    const res = yield call(apis.getSpaces, params)
    const normalized = normalizeSpaces(res.data.hits.hits)
    yield put(setSpacesData(normalized.result, normalized.entities[spaceNormalizrSchemaKey]))
  } catch (error) {
    yield put(catchErrors(error))
  } finally {
    yield put(loadingStopped())
  }
}

export function* fetchSpaceDetailSaga(action) {
  try {
    yield put(loadingStarted())
    const id = action.payload.id
    const res = yield call(apis.getSpaceDetail, id, i18n.language)
    yield put(setSpaceDetailData(res.data.space))
  } catch (error) {
    switch (error.response.status) {
      case 404:
        yield put(setSpaceDetailData(null))
        break
      case 403:
        yield put(
          catchErrors(
            error.response?.data?.message || 'このアカウントは規約違反により削除されました。'
          )
        )
        break
      default:
        yield put(catchErrors(error))
        break
    }
  } finally {
    yield put(loadingStopped())
  }
}
