import { authClient } from 'lib/axios'
export const API_SIGN_IN = '/api/app/v1/users/sign_in'
export const API_SIGN_UP = '/api/app/v1/users'
export const API_FACEBOOK_SIGNUP = '/api/app/v1/oauth/facebook'
export const API_FACEBOOK_SIGNIN = '/api/app/v1/oauth/facebook/sign_in'
export const API_GOOGLE_SIGNUP = '/api/app/v1/oauth/google'
export const API_GOOGLE_SIGNIN = '/api/app/v1/oauth/google/sign_in'
export const API_VALIDATE_USER = '/api/app/v1/users/validate_user'
export const API_REQUEST_EMAIL_OTP = '/api/app/v1/users/send_otp_by_email'
export const API_VERIFY_OTP = '/api/app/v1/users/verify_otp'

type ValidateUserParams = { email: string } | { phone_number: string }

export const validateUser = async (params: ValidateUserParams) =>
  await authClient.post(API_VALIDATE_USER, params)

export const requestEmailOTP = async (email: string) =>
  await authClient.post(API_REQUEST_EMAIL_OTP, { email })

type SignInParams =
  | { email: string; salt: string; code: string }
  | { phone_number: string; code: string; session_info: string }

export const signIn = (params: SignInParams) => authClient.post(API_SIGN_IN, params)

type VerifyOTPParams = {
  email: string
  code: string
  salt: string
}

export const verifyOTP = async (params: VerifyOTPParams) =>
  await authClient.post(API_VERIFY_OTP, params)

type SignUpParams = {
  otp_type: string
  email: string
  first_name: string
  last_name: string
  country_id?: number
  phone_number: string
  id_token: string
  uid_google?: string
  uid_fb?: string
}

export const signUp = (params: SignUpParams) => authClient.post(API_SIGN_UP, params)

export const facebookSignUp = async (accessToken) =>
  await authClient.post(API_FACEBOOK_SIGNUP, { token: accessToken })

export const facebookSignIn = (accessToken) =>
  authClient.post(API_FACEBOOK_SIGNIN, { token: accessToken })

export const googleSignUp = async ({
  accessToken,
  uidGoogle,
  email,
  userImage,
  firstName,
  lastName,
}) =>
  await authClient.post(API_GOOGLE_SIGNUP, {
    token: accessToken,
    email: email,
    uid_google: uidGoogle,
    user_image_url: userImage,
    first_name: firstName,
    last_name: lastName,
  })

export const googleSignIn = (accessToken) =>
  authClient.post(API_GOOGLE_SIGNIN, { token: accessToken })
