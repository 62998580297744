import produce, { Draft } from 'immer'
import {
  AUTH_SIGNIN_REQUEST,
  AUTH_SIGNIN_SUCCESS,
  AUTH_SIGNIN_FAILURE,
  AUTH_SIGNUP_REQUEST,
  AUTH_SIGNUP_SUCCESS,
  AUTH_SIGNUP_FAILURE,
  FACEBOOK_SIGNUP_REQUEST,
  FACEBOOK_SIGNIN_REQUEST,
  SET_FACEBOOK_USER_DATA,
  GOOGLE_SIGNUP_REQUEST,
  GOOGLE_SIGNIN_REQUEST,
  SET_GOOGLE_USER_DATA,
} from './types'
import {
  signInRequest,
  signInSuccess,
  signInFailure,
  signUpRequest,
  signUpSuccess,
  signUpFailure,
  oauthFacebookSignUpRequest,
  oauthFacebookSignInRequest,
  setFacebookUserData,
  oauthGoogleSignUpRequest,
  oauthGoogleSignInRequest,
  setGoogleUserData,
} from './actions'

export type AuthState = Readonly<{
  accessToken: string | null
}>

const initialState: AuthState = {
  accessToken: null,
}

export type FacebookUserState = Readonly<{
  data: {
    result: {
      email: string
      first_name: string
      last_name: string
      uid_fb: string
      user_image_url: string
    }
  }
}>

const initialFacebookUserState: FacebookUserState = {
  data: {
    result: {
      email: '',
      first_name: '',
      last_name: '',
      uid_fb: '',
      user_image_url: '',
    },
  },
}

export type GoogleUserState = Readonly<{
  data: {
    result: {
      email: string
      first_name: string
      last_name: string
      uid_google: string
      user_image_url: string
    }
  }
}>

const initialGoogleUserState: GoogleUserState = {
  data: {
    result: {
      email: '',
      first_name: '',
      last_name: '',
      uid_google: '',
      user_image_url: '',
    },
  },
}

export const authReducer = produce(
  (
    draft: Draft<AuthState>,
    action:
      | ReturnType<typeof signInRequest>
      | ReturnType<typeof signInSuccess>
      | ReturnType<typeof signInFailure>
      | ReturnType<typeof signUpRequest>
      | ReturnType<typeof signUpSuccess>
      | ReturnType<typeof signUpFailure>
  ) => {
    switch (action.type) {
      case AUTH_SIGNIN_REQUEST:
        draft
        break
      case AUTH_SIGNIN_SUCCESS:
        draft
        break
      case AUTH_SIGNIN_FAILURE:
        draft
        break
      case AUTH_SIGNUP_REQUEST:
        draft
        break
      case AUTH_SIGNUP_SUCCESS:
        draft
        break
      case AUTH_SIGNUP_FAILURE:
        draft
        break
      default:
        draft
    }
  },
  initialState
)

export const facebookReducer = produce(
  (
    draft: Draft<FacebookUserState>,
    action:
      | ReturnType<typeof oauthFacebookSignUpRequest>
      | ReturnType<typeof oauthFacebookSignInRequest>
      | ReturnType<typeof setFacebookUserData>
  ) => {
    switch (action.type) {
      case FACEBOOK_SIGNUP_REQUEST:
        draft
        break
      case FACEBOOK_SIGNIN_REQUEST:
        draft
        break
      case SET_FACEBOOK_USER_DATA:
        draft.data.result = action.payload.facebookUser.result
        break
      default:
        draft
    }
  },
  initialFacebookUserState
)

export const googleReducer = produce(
  (
    draft: Draft<GoogleUserState>,
    action:
      | ReturnType<typeof oauthGoogleSignUpRequest>
      | ReturnType<typeof oauthGoogleSignInRequest>
      | ReturnType<typeof setGoogleUserData>
  ) => {
    switch (action.type) {
      case GOOGLE_SIGNUP_REQUEST:
        draft
        break
      case GOOGLE_SIGNIN_REQUEST:
        draft
        break
      case SET_GOOGLE_USER_DATA:
        draft.data.result = action.payload.googleUser.result
        break
      default:
        draft
    }
  },
  initialGoogleUserState
)
