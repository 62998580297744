import { all, spawn, call } from 'redux-saga/effects'
import { combineReducers } from '@reduxjs/toolkit'
import { appReducer as app, AppState } from './app'
import {
  authReducer as auth,
  AuthState,
  authWatcherSagas,
  facebookReducer as facebookUser,
  FacebookUserState,
  googleReducer as googleUser,
  GoogleUserState,
} from './auth'
import {
  lockerPlanReducer as lockerPlan,
  LockerPlanState,
  lockerPlanWatcherSagas,
} from './lockerPlan'
import {
  userReducer as user,
  UserState,
  userWatcherSagas,
  creditCardReducer as creditCard,
  CreditCardState,
} from './user'
import {
  spacesReducer as spaces,
  SpacesState,
  spaceWatcherSagas,
  spaceDetailReducer as spaceDetail,
  SpaceDetailState,
} from './space'
import {
  bookingSuccessReducer as bookingSuccess,
  bookingWatcherSagas,
  bookingDetailReducer as bookingDetail,
  BookingDetailState,
  bookingInfoReducer as bookingInfo,
  BookingInfoState,
  CancelBookingState,
  successCancelBookingReducer as successCancelBooking,
  bookingsReducer as bookings,
} from './booking'
import {
  prefecturesReducer as prefectures,
  prefecturesWatcherSagas,
  PrefecturesState,
  stationsReducer as stations,
  StationsState,
  stationsWatcherSagas,
  facilitiesReducer as facilities,
  FacilitiesState,
  facilitiesWatcherSagas,
  locationDetailReducer as locationDetail,
  locationDetailWatcherSagas,
  LocationDetailState,
} from './prefecture'
import { BookingSuccessState, BookingsState } from './booking/reducers'

import {
  locationWatcherSagas,
  LocationDetailState as _LocationDetailState,
  locationDetailReducer as _locationDetail,
  LocationPopularAreasState,
  LocationPopularAreasReducer as locationPopularAreas,
  LocationRecommendedSpacesState,
  LocationRecommendedSpacesReducer as locationRecommendedSpaces,
  LocationCoinLockersState,
  LocationCoinLockersReducer as locationCoinLockers,

  // 駅
  LocationStationsState,
  LocationStationsReducer as locationStations,

  // 施設
  LocationFacilitiesState,
  LocationFacilitiesReducer as locationFacilities,
} from './locations'

import {
  CountriesState,
  CountriesReducer,
  countriesSagas,
  CitiesState,
  CitiesReducer,
  citiesSagas,
} from './country'

export type StoreState = Readonly<{
  app: AppState
  auth: AuthState
  facebookUser: FacebookUserState
  googleUser: GoogleUserState
  lockerPlan: LockerPlanState
  user: UserState
  creditCard: CreditCardState
  spaces: SpacesState
  spaceDetail: SpaceDetailState
  bookingSuccess: BookingSuccessState
  bookingInfo: BookingInfoState
  bookingDetail: BookingDetailState
  cancelBookingStatus: CancelBookingState
  bookings: BookingsState
  prefectures: PrefecturesState
  stations: StationsState
  facilities: FacilitiesState

  // locations
  locationDetail: LocationDetailState
  _locationDetail: _LocationDetailState
  locationPopularAreas: LocationPopularAreasState
  locationRecommendedSpaces: LocationRecommendedSpacesState
  locationCoinLockers: LocationCoinLockersState
  locationStations: LocationStationsState
  locationFacilities: LocationFacilitiesState

  // country
  countries: CountriesState
  cities: CitiesState
}>

export const rootReducer = combineReducers({
  app,
  auth,
  facebookUser,
  googleUser,
  lockerPlan,
  user,
  creditCard,
  spaces,
  spaceDetail,
  bookingSuccess,
  bookingInfo,
  bookingDetail,
  successCancelBooking,
  bookings,
  prefectures,
  stations,
  facilities,
  locationDetail,

  _locationDetail,
  locationPopularAreas,
  locationRecommendedSpaces,
  locationCoinLockers,
  locationStations,
  locationFacilities,

  // country
  countries: CountriesReducer,
  cities: CitiesReducer,
})

// @see {@link https://redux-saga.js.org/docs/advanced/RootSaga.html}
export function* rootSaga() {
  const watchers = [
    ...lockerPlanWatcherSagas,
    ...authWatcherSagas,
    ...userWatcherSagas,
    ...spaceWatcherSagas,
    ...bookingWatcherSagas,
    ...prefecturesWatcherSagas,
    ...stationsWatcherSagas,
    ...facilitiesWatcherSagas,
    ...locationDetailWatcherSagas,
    ...locationWatcherSagas,
    ...countriesSagas,
    ...citiesSagas,
  ]

  yield all(
    watchers.map((saga) =>
      // eslint-disable-next-line func-names
      spawn(function* () {
        while (true) {
          try {
            yield call(saga)
            break
          } catch (ex) {
            // eslint-disable-next-line no-console
          }
        }
      })
    )
  )
}
