import {
  AUTH_SIGNIN_REQUEST,
  AUTH_SIGNIN_SUCCESS,
  AUTH_SIGNIN_FAILURE,
  AUTH_SIGNUP_REQUEST,
  AUTH_SIGNUP_SUCCESS,
  AUTH_SIGNUP_FAILURE,
  FACEBOOK_SIGNUP_REQUEST,
  SET_FACEBOOK_USER_DATA,
  FACEBOOK_SIGNIN_REQUEST,
  GOOGLE_SIGNUP_REQUEST,
  GOOGLE_SIGNIN_REQUEST,
  SET_GOOGLE_USER_DATA,
} from './types'

type SignInRequestParams =
  | { email: string; salt: string; code: string }
  | {
      phone_number: string
      code: string
      session_info: string
      uid_google?: string
      uid_fb?: string
    }

export const signInRequest = (
  params: SignInRequestParams
): Readonly<{
  type: 'AUTH_SIGNIN_REQUEST'
  payload: {
    auth: SignInRequestParams
  }
}> => {
  let _params

  if ('session_info' in params) {
    _params = {
      phone_number: params.phone_number,
      code: params.code,
      session_info: params.session_info,
    }

    if ('uid_google' in params) {
      _params.uid_google = params.uid_google
    }

    if ('uid_fb' in params) {
      _params.uid_fb = params.uid_fb
    }
  } else {
    _params = {
      email: params.email,
      salt: params.salt,
      code: params.code,
    }
  }

  return {
    type: AUTH_SIGNIN_REQUEST,
    payload: {
      auth: _params,
    },
  }
}

export const signInSuccess = (): Readonly<{
  type: 'AUTH_SIGNIN_SUCCESS'
}> => {
  return {
    type: AUTH_SIGNIN_SUCCESS,
  }
}

export const signInFailure = ({
  message,
}): Readonly<{
  type: 'AUTH_SIGNIN_FAILURE'
  payload: {
    message: string
  }
}> => {
  return {
    type: AUTH_SIGNIN_FAILURE,
    payload: {
      message,
    },
  }
}

type SignUpRequestParams = {
  otp_type?: 'email'
  email: string
  first_name: string
  last_name: string
  country_id?: number
  phone_number: string
  uid_google?: string
}

export const signUpRequest = (
  params: SignUpRequestParams
): Readonly<{
  type: 'AUTH_SIGNUP_REQUEST'
  payload: {
    auth: SignUpRequestParams
  }
}> => {
  return {
    type: AUTH_SIGNUP_REQUEST,
    payload: {
      auth: params,
    },
  }
}

export const signUpSuccess = (): Readonly<{
  type: 'AUTH_SIGNUP_SUCCESS'
}> => {
  return {
    type: AUTH_SIGNUP_SUCCESS,
  }
}

export const signUpFailure = ({
  message,
}): Readonly<{
  type: 'AUTH_SIGNUP_FAILURE'
  payload: {
    message: string
  }
}> => {
  return {
    type: AUTH_SIGNUP_FAILURE,
    payload: {
      message,
    },
  }
}

export const oauthFacebookSignUpRequest = (
  accessToken: string
): Readonly<{
  type: 'FACEBOOK_SIGNUP_REQUEST'
  payload: {
    accessToken: string
  }
}> => {
  return {
    type: FACEBOOK_SIGNUP_REQUEST,
    payload: {
      accessToken,
    },
  }
}

export const setFacebookUserData = (
  result: any
): Readonly<{
  type: 'SET_FACEBOOK_USER_DATA'
  payload: {
    facebookUser: {
      result: any
    }
  }
}> => {
  return {
    type: SET_FACEBOOK_USER_DATA,
    payload: {
      facebookUser: { result },
    },
  }
}

export const oauthFacebookSignInRequest = (
  accessToken: string
): Readonly<{
  type: 'FACEBOOK_SIGNIN_REQUEST'
  payload: {
    accessToken: string
  }
}> => {
  return {
    type: FACEBOOK_SIGNIN_REQUEST,
    payload: {
      accessToken,
    },
  }
}

export const oauthGoogleSignUpRequest = (
  accessToken: string,
  uidGoogle: string,
  email: string,
  userImage: string
): Readonly<{
  type: 'GOOGLE_SIGNUP_REQUEST'
  payload: {
    accessToken: string
    uidGoogle: string
    email: string
    userImage: string
  }
}> => {
  return {
    type: GOOGLE_SIGNUP_REQUEST,
    payload: {
      accessToken,
      uidGoogle,
      email,
      userImage,
    },
  }
}

export const oauthGoogleSignInRequest = (
  accessToken: string
): Readonly<{
  type: 'GOOGLE_SIGNIN_REQUEST'
  payload: {
    accessToken: string
  }
}> => {
  return {
    type: GOOGLE_SIGNIN_REQUEST,
    payload: {
      accessToken,
    },
  }
}

export const setGoogleUserData = (
  result: any
): Readonly<{
  type: 'SET_GOOGLE_USER_DATA'
  payload: {
    googleUser: {
      result: any
    }
  }
}> => {
  return {
    type: SET_GOOGLE_USER_DATA,
    payload: {
      googleUser: { result },
    },
  }
}
