import * as apis from './apis'
import { call, put } from 'redux-saga/effects'
import {
  setPrefecturesData,
  setStationsData,
  setFacilitiesData,
  setLocationDetailData,
} from './actions'
import { loadingStopped, loadingStarted, catchErrors } from '../app/actions'

export function* fetchPrefecturesSaga() {
  try {
    yield put(loadingStarted())
    const res = yield call(apis.getPrefectures)
    yield put(setPrefecturesData(res.data))
  } catch (error) {
    yield put(catchErrors(error))
  } finally {
    yield put(loadingStopped())
  }
}

export function* fetchStationsSaga(action) {
  try {
    yield put(loadingStarted())
    const prefectureId = action.payload.id
    const res = yield call(apis.getStations, prefectureId)
    yield put(setStationsData(res.data))
  } catch (error) {
    yield put(catchErrors(error))
  } finally {
    yield put(loadingStopped())
  }
}

export function* fetchFacilitiesSaga(action) {
  try {
    yield put(loadingStarted())
    const prefectureId = action.payload.id
    const res = yield call(apis.getFacilities, prefectureId)
    yield put(setFacilitiesData(res.data))
  } catch (error) {
    yield put(catchErrors(error))
  } finally {
    yield put(loadingStopped())
  }
}

export function* fetchLocationDetailSaga(action) {
  try {
    yield put(loadingStarted())
    const label = action.payload.label
    const slug = action.payload.slug

    let endpoint
    if (label === 'prefecture') {
      endpoint = apis.getPrefectureDetail
    } else if (label === 'station') {
      endpoint = apis.getStationDetail
    } else if (label === 'facility') {
      endpoint = apis.getFacilityDetail
    }
    const res = yield call(endpoint, slug)
    yield put(setLocationDetailData(res.data))
  } catch (error) {
    yield put(catchErrors(error))
  } finally {
    yield put(loadingStopped())
  }
}
