import * as apis from './apis'
import { call, put } from 'redux-saga/effects'
import { fetchLockerPlanSuccess } from './actions'
import { loadingStopped, loadingStarted, catchErrors, refreshErrors } from '../app/actions'
import { normalizeLockerPlans, lockerPlanNormalizrSchemaKey } from './models'

export function* fetchLockerPlanSaga() {
  try {
    yield put(loadingStarted())
    yield put(refreshErrors())
    let lockerPlans = yield call(apis.getLockerPlans)
    lockerPlans = lockerPlans.data.map((el) => {
      return {
        id: el.id,
        planType: el.plan_type,
        price: el.price,
        createdAt: el.created_at,
        updatedAt: el.updated_at,
      }
    })
    const normalized = normalizeLockerPlans(lockerPlans)
    if (normalized.result.length !== 0) {
      yield put(
        fetchLockerPlanSuccess(normalized.result, normalized.entities[lockerPlanNormalizrSchemaKey])
      )
    } else {
      yield put(fetchLockerPlanSuccess([], {}))
    }
  } catch (error) {
    yield put(catchErrors(error))
  } finally {
    yield put(loadingStopped())
  }
}
