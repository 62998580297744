//Location - 詳細
export const FETCH_LOCATION_DETAIL_REQUEST = 'FETCH_LOCATION_DETAIL_REQUEST'
export const SET_LOCATION_DETAIL_DATA = 'SET_LOCATION_DETAIL_DATA'

//人気預かりエリア
export const FETCH_LOCATION_POPULAR_AREAS_REQUEST = 'FETCH_LOCATION_POPULAR_AREAS_REQUEST'
export const SET_LOCATION_POPULAR_AREAS_DATA = 'SET_LOCATION_POPULAR_AREAS_DATA'

//おすすめされた預かり場所
export const FETCH_LOCATION_RECOMMENDED_SPACES_REQUEST = 'FETCH_LOCATION_RECOMMENDED_SPACES_REQUEST'
export const SET_LOCATION_RECOMMENDED_SPACES_DATA = 'SET_LOCATION_RECOMMENDED_SPACES_DATA'

// コインロッカー一覧
export const FETCH_LOCATION_COIN_LOCKERS_REQUEST = 'FETCH_LOCATION_COIN_LOCKERS_REQUEST'
export const SET_LOCATION_COIN_LOCKERS_DATA = 'SET_LOCATION_COIN_LOCKERS_DATA'

// 駅一覧
export const FETCH_LOCATION_STATIONS_REQUEST = 'FETCH_LOCATION_STATIONS_REQUEST'
export const SET_LOCATION_STATIONS_DATA = 'SET_LOCATION_STATIONS_DATA'

// 施設一覧
export const FETCH_LOCATION_FACILITIES_REQUEST = 'FETCH_LOCATION_FACILITIES_REQUEST'
export const SET_LOCATION_FACILITIES_DATA = 'SET_LOCATION_FACILITIES_DATA'
