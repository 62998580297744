import { spaceClient } from 'lib/axios'

const API_COUNTRY = '/api/app/v1/countries'
const API_CITIES = '/api/app/v1/cities'

/**
 * 国情報を返す
 * @returns
 */
export const getCountries = () => spaceClient.get(API_COUNTRY, {})

/**
 * 州一覧を返す
 * @param {string} countryCode 国コード
 * @returns
 */
export const getCities = (countryCode) =>
  spaceClient.get(`${API_CITIES}?country_code=${countryCode}`, {})
