import {
  SET_SPACES_DATA,
  FETCH_SPACES_REQUEST,
  FETCH_SPACE_DETAIL_REQUEST,
  SET_SPACE_DETAIL_DATA,
} from './types'
import { Filter, NormalizedSpace, NormalizedSpaces, SpaceDetail } from './models'

export const fetchSpacesDataRequest = (
  params: Filter
): Readonly<{
  type: 'FETCH_SPACES_REQUEST'
  payload: {
    params: Filter
  }
}> => {
  return {
    type: FETCH_SPACES_REQUEST,
    payload: {
      params: params,
    },
  }
}

export const setSpacesData = (
  result: NormalizedSpace['_id'][],
  entities: NormalizedSpaces
): Readonly<{
  type: 'SET_SPACES_DATA'
  payload: {
    spaces: {
      result: NormalizedSpace['_id'][]
      entities: NormalizedSpaces
    }
  }
}> => {
  return {
    type: SET_SPACES_DATA,
    payload: {
      spaces: { result, entities },
    },
  }
}

export const fetchSpaceDetailRequest = (
  id: string | string[]
): Readonly<{
  type: 'FETCH_SPACE_DETAIL_REQUEST'
  payload: {
    id: string | string[]
  }
}> => {
  return {
    type: FETCH_SPACE_DETAIL_REQUEST,
    payload: {
      id: id,
    },
  }
}

export const setSpaceDetailData = (
  result: SpaceDetail
): Readonly<{
  type: 'SET_SPACE_DETAIL_DATA'
  payload: {
    spaceDetail: {
      result: SpaceDetail
    }
  }
}> => {
  return {
    type: SET_SPACE_DETAIL_DATA,
    payload: {
      spaceDetail: { result },
    },
  }
}
