import {
  FETCH_USER_REQUEST,
  FETCH_UPDATE_CREDIT_CARD_REQUEST,
  FETCH_DELETE_CREDIT_CARD_REQUEST,
  FETCH_CREDIT_CARD_REQUEST,
  FETCH_SET_USER_ID_REQUEST,
  FETCH_UPDATE_USER_REQUEST,
  FETCH_DELETE_USER_REQUEST,
} from './types'
import { takeLatest } from 'redux-saga/effects'
import {
  fetchUserSaga,
  fetchUpdateCreditCardSaga,
  fetchDeleteCreditCardSaga,
  fetchCreditCardSaga,
  fetchSetUserIdSaga,
  fetchUpdateUserSaga,
  fetchDeleteUserSaga,
} from './sagas'

export function* watchUserSaga() {
  yield takeLatest(FETCH_SET_USER_ID_REQUEST, fetchSetUserIdSaga)
  yield takeLatest(FETCH_USER_REQUEST, fetchUserSaga)
  yield takeLatest(FETCH_UPDATE_USER_REQUEST, fetchUpdateUserSaga)
  yield takeLatest(FETCH_DELETE_USER_REQUEST, fetchDeleteUserSaga)
}

export function* watchCreditCard() {
  yield takeLatest(FETCH_UPDATE_CREDIT_CARD_REQUEST, fetchUpdateCreditCardSaga)
  yield takeLatest(FETCH_DELETE_CREDIT_CARD_REQUEST, fetchDeleteCreditCardSaga)
  yield takeLatest(FETCH_CREDIT_CARD_REQUEST, fetchCreditCardSaga)
}
