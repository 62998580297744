import produce, { Draft } from 'immer'
import {
  FETCH_COUNTRIES_REQUEST,
  SET_COUNTRIES_DATA,
  FETCH_CITIES_REQUEST,
  SET_CITIES_DATA,
} from './types'

import {
  fetchCountriesRequest,
  setCountriesData,
  fetchCitiesRequest,
  setCitiesData,
} from './actions'

import { Countries, City } from './models'

const initialState = {
  data: { result: [], entities: {} },
}

export type CountriesState = Readonly<{
  data: {
    result: Countries
  }
}>

export const CountriesReducer = produce(
  (
    draft: Draft<CountriesState>,
    action: ReturnType<typeof fetchCountriesRequest> | ReturnType<typeof setCountriesData>
  ) => {
    switch (action.type) {
      case FETCH_COUNTRIES_REQUEST:
        draft
        break
      case SET_COUNTRIES_DATA:
        draft.data.result = action.payload.countries.result
        break
      default:
        draft
    }
  },
  initialState
)

//---- 州

export type CitiesState = Readonly<{
  data: {
    result: City[]
  }
}>

export const CitiesReducer = produce(
  (
    draft: Draft<CitiesState>,
    action: ReturnType<typeof fetchCitiesRequest> | ReturnType<typeof setCitiesData>
  ) => {
    switch (action.type) {
      case FETCH_CITIES_REQUEST:
        draft
        break
      case SET_CITIES_DATA:
        draft.data.result = action.payload.cities.result
        break
      default:
        draft
    }
  },
  initialState
)
