import produce, { Draft } from 'immer'
import {
  START_LOAD,
  STOP_LOAD,
  CATCH_ERRORS,
  REFRESH_ERRORS,
  SET_OPEN_UPDATE_PHONE_MODAL,
} from './types'
import { loadingStarted, loadingStopped, catchErrors, refreshErrors } from './actions'

export type AppState = Readonly<{
  isLoading: boolean
  errors: {
    message: string
  }
}>

const initialState: AppState = {
  isLoading: false,
  errors: {
    message: '',
  },
}

export const appReducer = produce(
  (
    draft: Draft<AppState>,
    action:
      | ReturnType<typeof loadingStarted>
      | ReturnType<typeof loadingStopped>
      | ReturnType<typeof catchErrors>
      | ReturnType<typeof refreshErrors>
  ) => {
    switch (action.type) {
      case START_LOAD:
        draft.isLoading = true
        break
      case STOP_LOAD:
        draft.isLoading = false
        break
      case CATCH_ERRORS:
        draft.errors.message = action.payload.message
        break
      case REFRESH_ERRORS:
        draft.errors.message = ''
        break
      default:
        draft
    }
  },
  initialState
)
