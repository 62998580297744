import {
  SET_PREFECTURES_DATA,
  FETCH_PREFECTURES_REQUEST,
  SET_STATIONS_DATA,
  FETCH_STATIONS_REQUEST,
  SET_FACILITIES_DATA,
  FETCH_FACILITIES_REQUEST,
  SET_LOCATION_DETAIL_DATA,
  FETCH_LOCATION_DETAIL_REQUEST,
} from './types'
import { Prefectures, Stations, Facilities, LocationDetail } from './models'

export const fetchPrefecturesDataRequest = (): Readonly<{
  type: 'FETCH_PREFECTURES_REQUEST'
}> => {
  return {
    type: FETCH_PREFECTURES_REQUEST,
  }
}

export const setPrefecturesData = (
  result: Prefectures
): Readonly<{
  type: 'SET_PREFECTURES_DATA'
  payload: {
    prefectures: {
      result: Prefectures
    }
  }
}> => {
  return {
    type: SET_PREFECTURES_DATA,
    payload: {
      prefectures: { result },
    },
  }
}

export const fetchStationsDataRequest = (
  prefectureId: number
): Readonly<{
  type: 'FETCH_STATIONS_REQUEST'
  payload: {
    id: number
  }
}> => {
  return {
    type: FETCH_STATIONS_REQUEST,
    payload: {
      id: prefectureId,
    },
  }
}

export const setStationsData = (
  result: Stations
): Readonly<{
  type: 'SET_STATIONS_DATA'
  payload: {
    stations: {
      result: Stations
    }
  }
}> => {
  return {
    type: SET_STATIONS_DATA,
    payload: {
      stations: { result },
    },
  }
}

export const fetchFacilitiesDataRequest = (
  prefectureId: number
): Readonly<{
  type: 'FETCH_FACILITIES_REQUEST'
  payload: {
    id: number
  }
}> => {
  return {
    type: FETCH_FACILITIES_REQUEST,
    payload: {
      id: prefectureId,
    },
  }
}

export const setFacilitiesData = (
  result: Facilities
): Readonly<{
  type: 'SET_FACILITIES_DATA'
  payload: {
    facilities: {
      result: Facilities
    }
  }
}> => {
  return {
    type: SET_FACILITIES_DATA,
    payload: {
      facilities: { result },
    },
  }
}

export const fetchLocationDetailDataRequest = (
  slug: string | number,
  label: string
): Readonly<{
  type: 'FETCH_LOCATION_DETAIL_REQUEST'
  payload: {
    slug: string | number
    label: string
  }
}> => {
  return {
    type: FETCH_LOCATION_DETAIL_REQUEST,
    payload: {
      slug: slug,
      label: label,
    },
  }
}

export const setLocationDetailData = (
  result: LocationDetail
): Readonly<{
  type: 'SET_LOCATION_DETAIL_DATA'
  payload: {
    locationDetail: {
      result: LocationDetail
    }
  }
}> => {
  return {
    type: SET_LOCATION_DETAIL_DATA,
    payload: {
      locationDetail: { result },
    },
  }
}
