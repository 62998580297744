import {
  AUTH_SIGNIN_REQUEST,
  AUTH_SIGNUP_REQUEST,
  FACEBOOK_SIGNUP_REQUEST,
  FACEBOOK_SIGNIN_REQUEST,
  GOOGLE_SIGNUP_REQUEST,
  GOOGLE_SIGNIN_REQUEST,
} from './types'
import { takeLatest } from 'redux-saga/effects'
import {
  signInSaga,
  signUpSaga,
  facebookSignUpSage,
  facebookSignInSage,
  googleSignUpSage,
  googleSignInSage,
} from './sagas'

export function* watchAuthSaga() {
  yield takeLatest(AUTH_SIGNIN_REQUEST, signInSaga)
  yield takeLatest(AUTH_SIGNUP_REQUEST, signUpSaga)
}

export function* watchFacebookUserSaga() {
  yield takeLatest(FACEBOOK_SIGNUP_REQUEST, facebookSignUpSage)
  yield takeLatest(FACEBOOK_SIGNIN_REQUEST, facebookSignInSage)
}

export function* watchGoogleUserSaga() {
  yield takeLatest(GOOGLE_SIGNUP_REQUEST, googleSignUpSage)
  yield takeLatest(GOOGLE_SIGNIN_REQUEST, googleSignInSage)
}
