import getLocaleHeaderByLocale from '../../../lib/getLocaleHeaderByLocale'
import { bookingClient, spaceClient } from 'lib/axios'

const API_BOOKING = '/api/app/v1/bookings'
const API_BOOKING_INFO = '/api/app/v1/bookings/info'
const API_BOOKING_DETAIL = '/api/app/v1/bookings/'
const API_CANCEL_BOOKING = '/api/app/v1/bookings/'
const API_FETCH_PAYMENT_INTENTS = '/api/app/v1/bookings/payment_intents'

type FetchPaymentIntentParams = {
  space_id: string
  booked_from: string
  booked_to: string
  small_lockers_count: number
  large_lockers_count: number
}

export const fetchPaymentIntents = async (
  params: FetchPaymentIntentParams,
  accessToken: string
) => {
  try {
    const { space_id, booked_from, booked_to, small_lockers_count, large_lockers_count } = params
    const response = await bookingClient.post(
      API_FETCH_PAYMENT_INTENTS,
      {
        space_id,
        booked_from,
        booked_to,
        small_lockers_count,
        large_lockers_count,
      },
      {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      }
    )

    return { isSuccess: true, data: response.data }
  } catch (error) {
    return { isSuccess: false }
  }
}

export const postBooking = (accessToken, params) =>
  bookingClient.post(
    API_BOOKING,
    {
      space_id: params.space_id,
      booked_from: params.booked_from,
      booked_to: params.booked_to,
      small_lockers_count: Number(params.bag_size),
      large_lockers_count: Number(params.suitcase_size),
      source: params.source ?? 0,
    },
    {
      headers: {
        Authorization: `Token ${accessToken}`,
      },
    }
  )

export const getBookingInfo = (accessToken, params) =>
  bookingClient.get(API_BOOKING_INFO, {
    params: {
      space_id: params.space_id,
      booked_from: params.booked_from,
      booked_to: params.booked_to,
      small_lockers_count: Number(params.bag_size),
      large_lockers_count: Number(params.suitcase_size),
    },
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })

export const getBookingDetail = (accessToken, bookingId) =>
  bookingClient.get(API_BOOKING_DETAIL + bookingId, {
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })

export const cancelBooking = (accessToken, bookingId) =>
  bookingClient.patch(
    API_CANCEL_BOOKING + `${bookingId}/cancel`,
    { encrypted_id: bookingId },
    {
      headers: {
        Authorization: `Token ${accessToken}`,
      },
    }
  )

export const getBookings = (accessToken) =>
  bookingClient.get(API_BOOKING_DETAIL, {
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })

type IGetBookingStatusParams = {
  space_id: string
  start_date: string
  end_date: string
}

export const getBookingStatus: (data: IGetBookingStatusParams) => Promise<any> = async (data) => {
  try {
    const response = await spaceClient.post(`/api/app/v1/spaces/${data.space_id}/bookings_status`, {
      start_date: data.start_date,
      end_date: data.end_date,
    })

    return {
      isOk: true,
      data: response.data,
    }
  } catch (error) {
    return {
      isOk: false,
    }
  }
}

type ICheckBookingAvailabilityParams = {
  space_id: string
  booked_from: string
  booked_to: string
  small_lockers_count: number
  large_lockers_count: number
}

export const checkBookingAvailability: (
  data: ICheckBookingAvailabilityParams,
  accessToken: string,
  locale: string
) => Promise<any> = async (data, accessToken, locale) => {
  try {
    const response = await bookingClient.get('/api/app/v1/bookings/validate', {
      params: {
        ...data,
      },
      headers: {
        Authorization: `Token ${accessToken}`,
        LOCALE: getLocaleHeaderByLocale(locale || 'ja'),
      },
    })

    return {
      isOk: true,
      data: response.data,
    }
  } catch (error) {
    return {
      isOk: false,
      data: error?.response?.data,
    }
  }
}
