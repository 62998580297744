import produce, { Draft } from 'immer'
import {
  SET_USER_DATA,
  FETCH_USER_REQUEST,
  SET_CREDIT_CARD_DATA,
  FETCH_CREDIT_CARD_REQUEST,
} from './types'
import {
  setUserData,
  fetchUserDataRequest,
  setCreditCardData,
  fetchCreditCardDataRequest,
} from './actions'
import { User, CreditCard } from './models'

export type UserState = Readonly<{
  data: {
    result: User
  }
}>

export type CreditCardState = Readonly<{
  data: {
    result: CreditCard
  }
}>

const initialUserState: UserState = {
  data: {
    result: {
      country: {
        id: 0,
        name: '',
        en_name: '',
        country_code: '',
        thumbnail: '',
      },
      email: '',
      first_name: '',
      has_credit_card: false,
      has_required_info: false,
      intercom_user_hash: '',
      last_name: '',
      phone_number: '',
      user_id: '',
      user_image_url: '',
      user_seq: 0,
    },
  },
}

const initialCreditCardState = {
  data: {
    result: {
      success: false,
      card: {
        name: '',
        number: '',
        exp_month: 0,
        exp_year: '',
      },
    },
  },
}

export const userReducer = produce(
  (
    draft: Draft<UserState>,
    action: ReturnType<typeof setUserData> | ReturnType<typeof fetchUserDataRequest>
  ) => {
    switch (action.type) {
      case FETCH_USER_REQUEST:
        draft
        break
      case SET_USER_DATA:
        draft.data.result = action.payload.user.result
        break
      default:
        draft
    }
  },
  initialUserState
)

export const creditCardReducer = produce(
  (
    draft: Draft<CreditCardState>,
    action: ReturnType<typeof setCreditCardData> | ReturnType<typeof fetchCreditCardDataRequest>
  ) => {
    switch (action.type) {
      case FETCH_CREDIT_CARD_REQUEST:
        draft
        break
      case SET_CREDIT_CARD_DATA:
        draft.data.result = action.payload.creditCard.result
        break
      default:
        draft
    }
  },
  initialCreditCardState
)
