import { FETCH_COUNTRIES_REQUEST, FETCH_CITIES_REQUEST } from './types'
import { takeLatest } from 'redux-saga/effects'
import { fetchCountriesSaga, fetchCitiesSaga } from './sagas'

// 国情報一覧
export function* watchCountriesSaga() {
  yield takeLatest(FETCH_COUNTRIES_REQUEST, fetchCountriesSaga)
}

// 州情報一覧
export function* watchCitiesSaga() {
  yield takeLatest(FETCH_CITIES_REQUEST, fetchCitiesSaga)
}
