import produce, { Draft } from 'immer'
import {
  SET_SPACES_DATA,
  FETCH_SPACES_REQUEST,
  SET_SPACE_DETAIL_DATA,
  FETCH_SPACE_DETAIL_REQUEST,
} from './types'
import {
  setSpacesData,
  fetchSpacesDataRequest,
  setSpaceDetailData,
  fetchSpaceDetailRequest,
} from './actions'
import { Spaces, NormalizedSpaces, SpaceDetail } from './models'

export type SpacesState = Readonly<{
  data: {
    result: Spaces['_id'][]
    entities: NormalizedSpaces
  }
}>

export type SpaceDetailState = Readonly<{ data: { result: SpaceDetail } }>

const initialState = {
  data: { result: [], entities: {} },
}

export const spacesReducer = produce(
  (
    draft: Draft<SpacesState>,
    action: ReturnType<typeof setSpacesData> | ReturnType<typeof fetchSpacesDataRequest>
  ) => {
    switch (action.type) {
      case FETCH_SPACES_REQUEST:
        draft
        break
      case SET_SPACES_DATA:
        draft.data.result = action.payload.spaces.result
        draft.data.entities = action.payload.spaces.entities
        break
      default:
        draft
    }
  },
  initialState
)

export const spaceDetailReducer = produce(
  (
    draft: Draft<SpaceDetailState>,
    action: ReturnType<typeof setSpaceDetailData> | ReturnType<typeof fetchSpaceDetailRequest>
  ) => {
    switch (action.type) {
      case FETCH_SPACE_DETAIL_REQUEST:
        draft
        break
      case SET_SPACE_DETAIL_DATA:
        draft.data.result = action.payload.spaceDetail.result
        break
      default:
        draft
    }
  },
  initialState
)
