import * as watcherSagas from './watcherSagas'

export {
  locationDetailReducer,
  LocationPopularAreasReducer,
  LocationRecommendedSpacesReducer,
  LocationCoinLockersReducer,
  LocationStationsReducer,
  LocationFacilitiesReducer,
} from './reducers'
export type {
  LocationDetailState,
  LocationPopularAreasState,
  LocationRecommendedSpacesState,
  LocationCoinLockersState,
  LocationStationsState,
  LocationFacilitiesState,
} from './reducers'

export const locationWatcherSagas = Object.values(watcherSagas)
