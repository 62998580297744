import { BookingDetail, BookingInfo, Bookings, Params } from './models'
import {
  FETCH_BOOKING_REQUEST,
  SET_BOOKING_ID,
  FETCH_BOOKING_INFO_REQUEST,
  SET_BOOKING_INFO_DATA,
  FETCH_BOOKING_DETAIL_REQUEST,
  SET_BOOKING_DETAIL_DATA,
  FETCH_CANCEL_BOOKING_REQUEST,
  SET_SUCCESS_CANCEL_BOOKING,
  FETCH_BOOKINGS_REQUEST,
  SET_BOOKINGS_DATA,
} from './types'

export const fetchBookingDataRequest = (
  accessToken: string,
  params: Params
): Readonly<{
  type: 'FETCH_BOOKING_REQUEST'
  payload: {
    accessToken: string
    params: Params
  }
}> => {
  return {
    type: FETCH_BOOKING_REQUEST,
    payload: {
      accessToken: accessToken,
      params: params,
    },
  }
}

export const setBookingSuccess = (
  status: number,
  id: string
): Readonly<{
  type: 'SET_BOOKING_ID'
  payload: {
    status: number
    bookingId: string
  }
}> => {
  return {
    type: SET_BOOKING_ID,
    payload: {
      status: status,
      bookingId: id,
    },
  }
}

export const fetchBookingInfoRequest = (
  accessToken: string,
  params: Params
): Readonly<{
  type: 'FETCH_BOOKING_INFO_REQUEST'
  payload: {
    accessToken: string
    params: Params
  }
}> => {
  return {
    type: FETCH_BOOKING_INFO_REQUEST,
    payload: {
      accessToken: accessToken,
      params: params,
    },
  }
}

export const setBookingInfo = (
  result: BookingInfo
): Readonly<{
  type: 'SET_BOOKING_INFO_DATA'
  payload: {
    bookingInfo: {
      result: BookingInfo
    }
  }
}> => {
  return {
    type: SET_BOOKING_INFO_DATA,
    payload: {
      bookingInfo: { result },
    },
  }
}

export const fetchBookingDetailRequest = (
  accessToken: string,
  bookingId: string | string[]
): Readonly<{
  type: 'FETCH_BOOKING_DETAIL_REQUEST'
  payload: {
    accessToken: string
    bookingId: string | string[]
  }
}> => {
  return {
    type: FETCH_BOOKING_DETAIL_REQUEST,
    payload: {
      accessToken: accessToken,
      bookingId: bookingId,
    },
  }
}

export const setBookingDetail = (
  result: BookingDetail
): Readonly<{
  type: 'SET_BOOKING_DETAIL_DATA'
  payload: {
    bookingDetail: {
      result: BookingDetail
    }
  }
}> => {
  return {
    type: SET_BOOKING_DETAIL_DATA,
    payload: {
      bookingDetail: { result },
    },
  }
}

export const fetchCancelBookingRequest = (
  accessToken: string,
  bookingId: string | string[]
): Readonly<{
  type: 'FETCH_CANCEL_BOOKING_REQUEST'
  payload: {
    accessToken: string
    bookingId: string | string[]
  }
}> => {
  return {
    type: FETCH_CANCEL_BOOKING_REQUEST,
    payload: {
      accessToken: accessToken,
      bookingId: bookingId,
    },
  }
}

export const setSuccessCancelBooking = (
  result: number
): Readonly<{
  type: 'SET_SUCCESS_CANCEL_BOOKING'
  payload: {
    status: {
      result: number
    }
  }
}> => {
  return {
    type: SET_SUCCESS_CANCEL_BOOKING,
    payload: {
      status: { result },
    },
  }
}

export const fetchBookingsRequest = (
  accessToken: string
): Readonly<{
  type: 'FETCH_BOOKINGS_REQUEST'
  payload: {
    accessToken: string
  }
}> => {
  return {
    type: FETCH_BOOKINGS_REQUEST,
    payload: {
      accessToken: accessToken,
    },
  }
}

export const setBookings = (
  result: Bookings
): Readonly<{
  type: 'SET_BOOKINGS_DATA'
  payload: {
    bookings: {
      result: Bookings
    }
  }
}> => {
  return {
    type: SET_BOOKINGS_DATA,
    payload: {
      bookings: { result },
    },
  }
}
