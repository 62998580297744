import { START_LOAD, STOP_LOAD, CATCH_ERRORS } from './types'

export const loadingStarted = (): Readonly<{
  type: 'START_LOAD'
}> => {
  return {
    type: START_LOAD,
  }
}

export const loadingStopped = (): Readonly<{
  type: 'STOP_LOAD'
}> => {
  return {
    type: STOP_LOAD,
  }
}

export const catchErrors = (
  message
): Readonly<{
  type: 'CATCH_ERRORS'
  payload: {
    message: string
  }
}> => {
  return {
    type: CATCH_ERRORS,
    payload: {
      message,
    },
  }
}

export const refreshErrors = (): Readonly<{
  type: 'REFRESH_ERRORS'
}> => {
  return {
    type: 'REFRESH_ERRORS',
  }
}
