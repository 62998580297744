import { spaceClient } from 'lib/axios'

const API_LOCATION_DETAIL = '/api/app/v1/locations/'
const API_LOCATION_POPULAR_AREAS = '/api/app/v1/locations/areas'
const API_LOCATION_RECOMMENDED_SPACES = '/api/app/v1/locations/spaces'
const API_LOCATIONS = '/api/app/v1/locations'
const API_LOCATION_COIN_LOCKERS = '/api/app/v1/locations/coin_lockers'
const API_LOCATION_STATIONS = '/api/app/v1/locations/stations'
const API_LOCATION_FACILITIES = '/api/app/v1/locations/facilities'
const API_LOCATION_AUTO_COMPLETION = '/api/app/v1/places/autocomplete'
const API_LOCATION_PLACE_DETAIL = '/api/app/v1/places/details'

//Location 詳細
export const getLocationDetail = (params, acceptLanguage?) =>
  spaceClient.post(`${API_LOCATION_DETAIL}${params.id}`, {
    type: params.type,
    country_code: params.country_code,
    slug: params.slug,
    headers: { 'Accept-Language': acceptLanguage },
  })

//人気預かりエリア
export const getLocationPopularAreas = (params) =>
  spaceClient.post(API_LOCATION_POPULAR_AREAS, {
    latitude: params.latitude,
    longitude: params.longitude,
    type: params.type,
    country_code: params.country_code,
    prefecture_id: params.prefecture_id,
    city_id: params.city_id,
  })

//おすすめ預かり場所
export const getLocationRecommendedSpaces = (params) =>
  spaceClient.post(
    API_LOCATION_RECOMMENDED_SPACES,
    {
      latitude: params.latitude,
      longitude: params.longitude,
      country_code: params.country_code,
      prefecture_id: params.prefecture_id,
      country_id: params.country_id,
      page: params.page,
      page_size: params.page_size,
      location_id: params.location_id ? Number(params.location_id) : null,
    },
    {
      headers: {
        'Accept-Language': params.language,
      },
    }
  )

// sitemap用endpoint
export const getLocations = (countryCode, prefectureId, cityId) =>
  spaceClient.get(API_LOCATIONS, {
    params: {
      country_code: countryCode,
      city_id: cityId,
      prefecture_id: prefectureId,
    },
  })

// コインロッカー一覧を取得する
export const getLocationCoinLockers = (params) =>
  spaceClient.post(
    API_LOCATION_COIN_LOCKERS,
    {
      latitude: params.latitude,
      longitude: params.longitude,
    },
    {
      headers: { 'Accept-Language': params.language },
    }
  )

// 駅一覧を取得する
export const getLocationStations = (countryCode, prefectureId, cityId) =>
  spaceClient.get(API_LOCATION_STATIONS, {
    params: {
      country_code: countryCode,
      city_id: cityId,
      prefecture_id: prefectureId,
    },
  })

// 施設一覧を取得する
export const getLocationFacilities = (countryCode, prefectureId, cityId) =>
  spaceClient.get(API_LOCATION_FACILITIES, {
    params: {
      country_code: countryCode,
      city_id: cityId,
      prefecture_id: prefectureId,
    },
  })

export const googleMapAutoCompletion = async (text: string) => {
  const response = await spaceClient.get(API_LOCATION_AUTO_COMPLETION, {
    params: {
      input: text,
    },
  })

  return response.data
}

export const goolgeMapGetPlaceDetail = async (placeId: string) => {
  const response = await spaceClient.get(API_LOCATION_PLACE_DETAIL, {
    params: {
      place_id: placeId,
    },
  })

  return response.data
}
