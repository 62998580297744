import * as watcherSagas from './watcherSagas'

export {
  prefecturesReducer,
  stationsReducer,
  facilitiesReducer,
  locationDetailReducer,
} from './reducers'
export type {
  PrefecturesState,
  StationsState,
  FacilitiesState,
  LocationDetailState,
} from './reducers'

export const prefecturesWatcherSagas = Object.values(watcherSagas)
export const stationsWatcherSagas = Object.values(watcherSagas)
export const facilitiesWatcherSagas = Object.values(watcherSagas)
export const locationDetailWatcherSagas = Object.values(watcherSagas)
