import {
  FETCH_COUNTRIES_REQUEST,
  SET_COUNTRIES_DATA,
  FETCH_CITIES_REQUEST,
  SET_CITIES_DATA,
} from './types'
import { Countries, City } from './models'

// 国情報一覧を取得
export const fetchCountriesRequest = (): Readonly<{
  type: 'FETCH_COUNTRIES_REQUEST'
  payload: {}
}> => {
  return {
    type: FETCH_COUNTRIES_REQUEST,
    payload: {},
  }
}

// 国情報一覧をセット
export const setCountriesData = (
  result: Countries
): Readonly<{
  type: 'SET_COUNTRIES_DATA'
  payload: {
    countries: {
      result: Countries
    }
  }
}> => {
  return {
    type: SET_COUNTRIES_DATA,
    payload: {
      countries: { result },
    },
  }
}

//------- 州

// 州情報一覧を取得
export const fetchCitiesRequest = (
  countryCode: string
): Readonly<{
  type: 'FETCH_CITIES_REQUEST'
  payload: {
    countryCode: string
  }
}> => {
  return {
    type: FETCH_CITIES_REQUEST,
    payload: {
      countryCode: countryCode,
    },
  }
}

// 州情報一覧をセット
export const setCitiesData = (
  result: City[]
): Readonly<{
  type: 'SET_CITIES_DATA'
  payload: {
    // cities: {
    //   result: Cities
    // }
    cities: {
      result: City[]
    }
  }
}> => {
  return {
    type: SET_CITIES_DATA,
    payload: {
      cities: { result },
    },
  }
}
