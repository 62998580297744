import produce, { Draft } from 'immer'
import {
  SET_PREFECTURES_DATA,
  FETCH_PREFECTURES_REQUEST,
  SET_STATIONS_DATA,
  FETCH_STATIONS_REQUEST,
  SET_FACILITIES_DATA,
  FETCH_FACILITIES_REQUEST,
  FETCH_LOCATION_DETAIL_REQUEST,
  SET_LOCATION_DETAIL_DATA,
} from './types'
import {
  setPrefecturesData,
  fetchPrefecturesDataRequest,
  setStationsData,
  fetchStationsDataRequest,
  setFacilitiesData,
  fetchFacilitiesDataRequest,
  fetchLocationDetailDataRequest,
  setLocationDetailData,
} from './actions'
import { Prefectures, Stations, Facilities, LocationDetail } from './models'

export type PrefecturesState = Readonly<{
  data: {
    result: Prefectures
  }
}>

export type StationsState = Readonly<{
  data: {
    result: Stations
  }
}>

export type FacilitiesState = Readonly<{
  data: {
    result: Facilities
  }
}>

export type LocationDetailState = Readonly<{
  data: {
    result: LocationDetail
  }
}>

const initialState = {
  data: { result: [] },
}

export const prefecturesReducer = produce(
  (
    draft: Draft<PrefecturesState>,
    action: ReturnType<typeof setPrefecturesData> | ReturnType<typeof fetchPrefecturesDataRequest>
  ) => {
    switch (action.type) {
      case FETCH_PREFECTURES_REQUEST:
        draft
        break
      case SET_PREFECTURES_DATA:
        draft.data.result = action.payload.prefectures.result
        break
      default:
        draft
    }
  },
  initialState
)

export const stationsReducer = produce(
  (
    draft: Draft<StationsState>,
    action: ReturnType<typeof setStationsData> | ReturnType<typeof fetchStationsDataRequest>
  ) => {
    switch (action.type) {
      case FETCH_STATIONS_REQUEST:
        draft
        break
      case SET_STATIONS_DATA:
        draft.data.result = action.payload.stations.result
        break
      default:
        draft
    }
  },
  initialState
)

export const facilitiesReducer = produce(
  (
    draft: Draft<FacilitiesState>,
    action: ReturnType<typeof setFacilitiesData> | ReturnType<typeof fetchFacilitiesDataRequest>
  ) => {
    switch (action.type) {
      case FETCH_FACILITIES_REQUEST:
        draft
        break
      case SET_FACILITIES_DATA:
        draft.data.result = action.payload.facilities.result
        break
      default:
        draft
    }
  },
  initialState
)

export const locationDetailReducer = produce(
  (
    draft: Draft<LocationDetailState>,
    action:
      | ReturnType<typeof setLocationDetailData>
      | ReturnType<typeof fetchLocationDetailDataRequest>
  ) => {
    switch (action.type) {
      case FETCH_LOCATION_DETAIL_REQUEST:
        draft
        break
      case SET_LOCATION_DETAIL_DATA:
        draft.data.result = action.payload.locationDetail.result
        break
      default:
        draft
    }
  },
  initialState
)
