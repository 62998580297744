import produce, { Draft } from 'immer'
import { FETCH_LOCKER_PLAN_REQUEST, FETCH_LOCKER_PLAN_SUCCESS } from './types'
import { fetchLockerPlanRequested, fetchLockerPlanSuccess } from './actions'
import { LockerPlan, NormalizedLockerPlans } from './models'

export type LockerPlanState = Readonly<{
  data: {
    ids: LockerPlan['id'][]
    entities: NormalizedLockerPlans
  }
}>

const initialState: LockerPlanState = {
  data: { ids: [], entities: {} },
}

export const lockerPlanReducer = produce(
  (
    draft: Draft<LockerPlanState>,
    action: ReturnType<typeof fetchLockerPlanRequested> | ReturnType<typeof fetchLockerPlanSuccess>
  ) => {
    switch (action.type) {
      case FETCH_LOCKER_PLAN_REQUEST:
        draft
        break
      case FETCH_LOCKER_PLAN_SUCCESS:
        draft.data.ids = action.payload.lockerPlan.ids
        draft.data.entities = action.payload.lockerPlan.entities
        break
      default:
        draft
    }
  },
  initialState
)
