// not working production build
declare global {
  interface Window {
    gtag: any
    dataLayer: Record<string, unknown>[]
  }
}

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID || ''
export const GTM_TRACKING_ID = process.env.NEXT_PUBLIC_GTM_ID || ''

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url,
  })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  })
}

export const GTM_EVENT_SING_UP_PV = 'sign-up-pv'
export const GTM_EVENT_SING_UP_COMPLETE = 'sign-up-complete'

export const GTM_EVENT_LOGIN_PV = 'login-pv'
export const GTM_EVENT_LOGIN_COMPLETE = 'login-complete'

export const GTM_EVENT_RESERVE_MODAL_PV = 'reserve-modal-pv'
export const GTM_EVENT_RESERVE_CONFIRM_PV = 'reserve-confirm-pv'
export const GTM_EVENT_RESERVE_COMPLETE = 'reserve-complete'

export const sendGtmEvent = ({ eventName }) => {
  window.dataLayer.push({ event: eventName })
}
